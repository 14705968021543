import URL from "./config";

const publishAltiorApplication = (altiorApplicationId, body) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL.BASE_URL}/api/v1/altior_applications/${altiorApplicationId}/publish`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        publish: body
      })
    })
      .then(res => {
        if (res.status > 300) {
          reject({
            error: {
              statusCode: res.status,
              reason: res.statusText
            }
          })
        } else {
          resolve("ok")
        }
      })
  });
};

const stopAltiorApplication = (altiorApplicationId) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL.BASE_URL}/api/v1/altior_applications/${altiorApplicationId}/publish`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
      .then(res => {
        if (res.status > 300) {
          reject({
            error: {
              statusCode: res.status,
              reason: res.statusText
            }
          })
        } else {
          resolve("ok")
        }
      })
  });
};

export default {
  publishAltiorApplication: publishAltiorApplication,
  stopAltiorApplication: stopAltiorApplication
};
