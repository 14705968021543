import {
  FETCH_ALTIOR_APPLICATIONS,
  FETCH_ALTIOR_APPLICATIONS_FAILURE,
  FETCH_ALTIOR_APPLICATIONS_SUCCESS,
  SHOW_ALTIOR_APPLICATIONS,
  SHOW_ALTIOR_APPLICATIONS_FAILURE,
  SHOW_ALTIOR_APPLICATIONS_SUCCESS,
  CREATE_ALTIOR_APPLICATION,
  CREATE_ALTIOR_APPLICATION_SUCCESS,
  CREATE_ALTIOR_APPLICATION_FAILURE,
  UPDATE_ALTIOR_APPLICATION,
  UPDATE_ALTIOR_APPLICATION_SUCCESS,
  UPDATE_ALTIOR_APPLICATION_FAILURE,
  START_CREATE_ALTIOR_APPLICATION,
  START_UPDATE_ALTIOR_APPLICATION,
  END_UPDATE_ALTIOR_APPLICATION,
  END_CREATE_ALTIOR_APPLICATION,
  START_PREPARE_FLOW_CHART,
  START_PREPARE_FLOW_CHART_SUCCESS,
  START_PREPARE_FLOW_CHART_ERROR,
  PUBLISH_ALTIOR_APPLICATION,
  PUBLISH_ALTIOR_APPLICATION_SUCCESS,
  PUBLISH_ALTIOR_APPLICATION_FAILURE,
  STOP_ALTIOR_APPLICATION,
  STOP_ALTIOR_APPLICATION_SUCCESS,
  STOP_ALTIOR_APPLICATION_FAILURE
} from "../actions/";

const altiorApplicationReducer = (
  state = {
    isLoading: false,
    items: [],
    error: null,
    isCreating: false,
    isUpdating: false,
    isPreparing: false,
    isPrepared: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_ALTIOR_APPLICATIONS:
      return { ...state, isLoading: true, error: null, items: [] };
    case FETCH_ALTIOR_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.altiorApplications.data,
        meta: action.altiorApplications.meta,
        error: null
      };
    case FETCH_ALTIOR_APPLICATIONS_FAILURE:
      return { ...state, isLoading: false, error: action.error, items: [] };
    case CREATE_ALTIOR_APPLICATION:
      return { ...state, isCreateLoading: true, error: null, item: null };
    case CREATE_ALTIOR_APPLICATION_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        error: null,
        items: [...state.items, action.altiorApplication.data],
        item: action.altiorApplication.data,
        isCreating: false
      };
    case CREATE_ALTIOR_APPLICATION_FAILURE:
      return {
        ...state,
        isCreateLoading: false,
        error: action.error,
        item: null,
        isCreating: false
      };
    case UPDATE_ALTIOR_APPLICATION:
      return {
        ...state,
        isLoading: true,
        error: null,
        item: null,
        isUpdating: true
      };
    case UPDATE_ALTIOR_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        item: action.altiorApplication.data,
        items: findAndReplace(state.items, action.altiorApplication.data),
        isUpdating: false,
        updateItem: null
      };
    case UPDATE_ALTIOR_APPLICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        item: null,
        isUpdating: false
      };
    case START_UPDATE_ALTIOR_APPLICATION:
      return { ...state, isUpdating: true, updateItem: action.updateItem };
    case START_CREATE_ALTIOR_APPLICATION:
      return { ...state, isCreating: true, updateItem: null };
    case END_UPDATE_ALTIOR_APPLICATION:
      return { ...state, isUpdating: false };
    case END_CREATE_ALTIOR_APPLICATION:
      return { ...state, isCreating: false, updateItem: null };
    case SHOW_ALTIOR_APPLICATIONS:
      return { ...state, isLoading: true, error: null, item: null };
    case SHOW_ALTIOR_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.altiorApplication.data,
        error: null
      };
    case SHOW_ALTIOR_APPLICATIONS_FAILURE:
      return { ...state, isLoading: false, error: action.error, item: null };
    case START_PREPARE_FLOW_CHART:
      return { ...state, isPreparing: true, isPrepared: false, error: null };
    case START_PREPARE_FLOW_CHART_SUCCESS:
      return { ...state, isPreparing: false, isPrepared: true, error: null };
    case START_PREPARE_FLOW_CHART_ERROR:
      return {
        ...state,
        isPreparing: false,
        isPrepared: false,
        error: action.error
      };
    case PUBLISH_ALTIOR_APPLICATION:
      return {
        ...state,
        isLoading: true,
        error: null,
        item: null,
        isPublished: false
      };
    case PUBLISH_ALTIOR_APPLICATION_SUCCESS:
      return {
        ...state,
        error: null,
        isPublished: true,
        isLoading: false
      };
    case PUBLISH_ALTIOR_APPLICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        isPublished: null,
        isLoading: false
      };
    case STOP_ALTIOR_APPLICATION:
      return {
        ...state,
        isLoading: true,
        isStopped: false,
        isPublished: false
      };
    case STOP_ALTIOR_APPLICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isStopped: false,
        error: action.error
      };
    case STOP_ALTIOR_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isStopped: true,
        isPublished: false
      };
    default:
      return state;
  }
};

const findAndReplace = (items, newItem) => {
  const index = items.findIndex(i => i.id == newItem.id);
  if (index == -1) {
    return [...items, newItem];
  }

  return items.map((element, i) => (i == index ? newItem : element));
};
export default altiorApplicationReducer;
