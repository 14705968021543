import {
  APPLICATION_DEBUG_START,
  APPLICATION_DEBUG_STARTED,
  APPLICATION_DEBUG_START_ERROR,
  APPLICATION_DEBUG_SET_EVENT_TYPE,
  APPLICATION_DEBUG_TRIGGER_EVENT,
  APPLICATION_DEBUG_TRIGGERED_EVENT,
  APPLICATION_DEBUG_TRIGGER_EVENT_ERROR,
  APPLICATION_DEBUG_STOP,
  APPLICATION_DEBUG_INIT_OK,
  APPLICATION_DEBUG_INIT
} from "../actionTypes";

import {
  consoleRest,
  consoleRestWithError,
  consolePushLog
} from "./consoleLog";

import {
  phoenixWebSocketPushMessage,
  phoenixWebSocketConnect,
  setAttributesOutputState,
  rigthSideMenuToggle
} from "../actions";

export const applicationDebugInit = () => {
  return dispatch => {
    dispatch(
      phoenixWebSocketConnect(process.env.REACT_APP_WS_URL, [
        {
          channel: process.env.REACT_APP_FLOWCHART_WS_CHANNEL,
          topics: ["new_msg"]
        }
      ])
    );
    dispatch({ type: APPLICATION_DEBUG_INIT });
  };
};

export const applicationDebugInitEnd = () => {
  return (dispatch, getState) => {
    if (getState().applicationDebugger.started) {
      dispatch(consoleRestWithError("Compilation Error!"));
    } else {
      dispatch(consoleRest());
    }
    dispatch({
      type: APPLICATION_DEBUG_INIT_OK
    });
  };
};

export const applicationDebugStart = flowChartData => {
  return dispatch => {
    dispatch(
      phoenixWebSocketPushMessage(
        process.env.REACT_APP_FLOWCHART_WS_CHANNEL,
        "start_debug",
        flowChartData
      )
    );
    dispatch({
      type: APPLICATION_DEBUG_START
    });
  };
};

export const applicationDebugStarted = () => {
  return dispatch => {
    //TODO: add the message to the i18n
    const message = {
      values: { message: "Apllication compiled and ready to receive events" }
    };
    dispatch(consolePushLog(message));
    dispatch({
      type: APPLICATION_DEBUG_STARTED
    });
  };
};

export const applicationDebugStartError = error => ({
  type: APPLICATION_DEBUG_START_ERROR,
  error: error
});

export const applicationDebugSetEventType = eventType => ({
  type: APPLICATION_DEBUG_SET_EVENT_TYPE,
  eventType: eventType
});

export const applicationDebugTriggerEventType = message => {
  return dispatch => {
    dispatch(
      phoenixWebSocketPushMessage(
        process.env.REACT_APP_FLOWCHART_WS_CHANNEL,
        "send_event",
        message
      )
    );

    dispatch(rigthSideMenuToggle("2"));

    dispatch({
      type: APPLICATION_DEBUG_TRIGGER_EVENT,
      eventName: message.event_name,
      initState: message.init_state
    });
  };
};

export const applicationDebugTriggeredEventType = (eventType, result) => ({
  type: APPLICATION_DEBUG_TRIGGERED_EVENT,
  eventType: eventType,
  result: result
});

export const applicationDebugStop = () => {
  return dispatch => {
    dispatch(
      phoenixWebSocketPushMessage(
        process.env.REACT_APP_FLOWCHART_WS_CHANNEL,
        "stop_debug"
      )
    );
    dispatch({ type: APPLICATION_DEBUG_STOP });
  };
};

export const applicationDebugReceiveMessage = (
  channel,
  messageType,
  payload
) => {
  return dispatch => {
    let message = "";
    switch (payload.body) {
      case "Event end":
        let newState = "{";
        const items = Object.keys(payload.new_state).map(
          key => `${key}: ${payload.new_state[key]} `
        );
        items.join(",");
        newState += items + "}";
        message = `${payload.body}. New State: ${newState}`;
        dispatch(
          setAttributesOutputState({
            attributesOutputState: payload.new_state
          })
        );
        break;
      case "AMQP Send":
        message = `${payload.body} to queue: ${payload.queue}.`;
        break;

      default:
        message = payload.body;
        break;
    }

    dispatch(consolePushLog({ values: { message: message } }));
  };
};
