import URL from './config'

const compile = (deviceTypeId) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types/${deviceTypeId}/compiler`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'}})
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{
              resolve(result.data);
          }
        })
      });
}

const compilationHistory = (deviceTypeId) => {
  return new Promise((resolve, reject) => {
      fetch(`${URL.BASE_URL}/api/v1/device_types/${deviceTypeId}/compiling_results`)
      .then(res => {
        if(res.status > 300){
            return {
                error: {
                    statusCode: res.status,
                    reason: res.statusText
                }
            }
        }else{
            return res.json()
        }
      })
      .then(result => {
        if(result.error) {
            console.log(result.errors)
            reject(result.error);
        }else{
            resolve(result.data);
        }
      })
    });
}

export default {compile: compile, compilationHistory: compilationHistory}
