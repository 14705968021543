import {
  SHOW_DEVICE_TYPE_OBJECT,
  SHOW_DEVICE_TYPE_OBJECT_FAILURE,
  SHOW_DEVICE_TYPE_OBJECT_SUCCESS,
  CREATE_DEVICE_TYPE_OBJECT,
  CREATE_DEVICE_TYPE_OBJECT_SUCCESS,
  CREATE_DEVICE_TYPE_OBJECT_FAILURE,
  CREATE_DEVICE_TYPE_OBJECT_TOOGLE,
  CREATE_DEVICE_TYPE_OBJECT_REQUEST_SENT,
  SEND_COMMAND_TO_DEVICE_TYPE_OBJECT,
  SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_FAILURE,
  SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_SUCCESS,
  DELETE_DEVICE_TYPE_OBJECT,
  DELETE_DEVICE_TYPE_OBJECT_FAILURE,
  DELETE_DEVICE_TYPE_OBJECT_SUCCESS,
  CLEAR_DEVICE_TYPE_OBJECT
} from "../actions/";

const deviceTypeObjectReducer = (
  state = {
    isLoading: false,
    item: null,
    identifier: null,
    error: null,
    isCreating: false,
    isUpdating: false,
    stateCreateDeviceTypeObjectModal: false,
    messages: [],
    commandName: null,
    commandValue: null,
    sendingCommand: false,
    errorSentCommand: null,
    isDeleting: false,
    isDeleted: false,
    lastCommandSent: false
  },
  action
) => {
  switch (action.type) {
    case SHOW_DEVICE_TYPE_OBJECT:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        isLoading: true,
        error: null,
        items: [],
        isCreating: false,
        lastCommandSent: false,
        isCreated: false
      };
    case SHOW_DEVICE_TYPE_OBJECT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        isLoading: false,
        lastCommandSent: false,
        item: action.deviceTypeObject,
        error: null
      };
    case SHOW_DEVICE_TYPE_OBJECT_FAILURE:
      return { ...state, isLoading: false, error: action.error, items: [] };

    case CREATE_DEVICE_TYPE_OBJECT:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        isCreating: true,
        error: null,
        item: null,
        lastCommandSent: false,
        isCreated: false,
        stateCreateDeviceTypeObjectModal: true,
        messages: [{ timestamp: new Date(), value: "Start creating object." }]
      };
    case CREATE_DEVICE_TYPE_OBJECT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        isCreating: false,
        error: null,
        identifier: action.payload.message.identifier,
        isCreating: false,
        isCreated: true,
        lastCommandSent: false,
        isUpdated: false,
        messages: [
          ...state.messages,
          { timestamp: new Date(), value: "Object created with success" }
        ]
      };
    case CREATE_DEVICE_TYPE_OBJECT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        isCreating: false,
        error: action.error,
        item: null,
        isCreated: false,
        isUpdated: false,
        lastCommandSent: false,
        messages: [
          ...state.messages,
          {
            timestamp: new Date(),
            value: `Object create with error: ${action.error}`
          }
        ]
      };
    case CREATE_DEVICE_TYPE_OBJECT_TOOGLE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        stateCreateDeviceTypeObjectModal:
          action.stateCreateDeviceTypeObjectModal
      };
    case CREATE_DEVICE_TYPE_OBJECT_REQUEST_SENT:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        lastCommandSent: false,
        messages: [
          ...state.messages,
          {
            timestamp: new Date(),
            value: `Request HTTP sent`
          }
        ]
      };
    case SEND_COMMAND_TO_DEVICE_TYPE_OBJECT:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        sendingCommand: true,
        errorSentCommand: false,
        lastCommandSent: false
      };
    case SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        sendingCommand: false,
        lastCommandSent: true,
        errorSentCommand: false
      };
    case SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        sendingCommand: false,
        errorSentCommand: action.error,
        lastCommandSent: false
      };
    case DELETE_DEVICE_TYPE_OBJECT:
      return { ...state, isDeleting: true, isDeleted: false, error: null };
    case DELETE_DEVICE_TYPE_OBJECT_SUCCESS:
      return { ...state, isDeleting: false, isDeleted: true, error: null };
    case DELETE_DEVICE_TYPE_OBJECT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error
      };
    case CLEAR_DEVICE_TYPE_OBJECT:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        isLoading: false,
        lastCommandSent: false,
        item: null,
        error: null
      };
    default:
      return state;
  }
};

const findAndReplace = (items, newItem) => {
  const index = items.findIndex(i => i.id == newItem.id);
  if (index == -1) {
    return [...items, newItem];
  }

  return items.map((element, i) => (i == index ? newItem : element));
};
export default deviceTypeObjectReducer;
