import {FETCH_USER_GROUPS_ERROR, FETCH_USER_GROUPS_REQUEST, FETCH_USER_GROUPS_SUCCES, FETCH_USER_GROUPS_SELECT_IF_NEEDED_SUCCES} from '../actions/'
import { act } from 'react-test-renderer';

export default (state = { isLoading: true}, action) => {

    switch (action.type) {

        case FETCH_USER_GROUPS_REQUEST:
            return { ...state, isLoading: true };
        case FETCH_USER_GROUPS_SUCCES:
            return { ...state, isLoading: false, items: action.data };
        case FETCH_USER_GROUPS_ERROR:
            return { ...state, isLoading: false, error: action.error };
        case FETCH_USER_GROUPS_SELECT_IF_NEEDED_SUCCES:
            return { ...state, isLoading: false, items: action.data, selectItems: prepareForSelect(action.data)  };
        default:
            return state;
    }
}


const prepareForSelect = (items) => 
    items.map(item =>{ return {"label": item.path, "data": item, "key": item.id}})
