import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_PERIPHERAL_CONFIGURATIONS =
  "FETCH_PERIPHERAL_CONFIGURATIONS";
export const FETCH_PERIPHERAL_CONFIGURATIONS_SUCCESS =
  "FETCH_PERIPHERAL_CONFIGURATIONS_SUCCESS";
export const FETCH_PERIPHERAL_CONFIGURATIONS_FAILURE =
  "FETCH_PERIPHERAL_CONFIGURATIONS_FAILURE";
export const START_CREATE_PERIPHERAL_CONFIGURATION =
  "START_CREATE_PERIPHERAL_CONFIGURATION";
export const CREATE_PERIPHERAL_CONFIGURATION =
  "CREATE_PERIPHERAL_CONFIGURATION";
export const CREATE_PERIPHERAL_CONFIGURATION_SUCCESS =
  "CREATE_PERIPHERAL_CONFIGURATION_SUCCESS";
export const CREATE_PERIPHERAL_CONFIGURATION_FAILURE =
  "CREATE_PERIPHERAL_CONFIGURATION_FAILURE";
export const END_CREATE_PERIPHERAL_CONFIGURATION =
  "END_CREATE_PERIPHERAL_CONFIGURATION";
export const START_UPDATE_PERIPHERAL_CONFIGURATION =
  "START_UPDATE_PERIPHERAL_CONFIGURATION";
export const UPDATE_PERIPHERAL_CONFIGURATION =
  "UPDATE_PERIPHERAL_CONFIGURATION";
export const UPDATE_PERIPHERAL_CONFIGURATION_SUCCESS =
  "UPDATE_PERIPHERAL_CONFIGURATION_SUCCESS";
export const UPDATE_PERIPHERAL_CONFIGURATION_FAILURE =
  "UPDATE_PERIPHERAL_CONFIGURATION_FAILURE";
export const END_UPDATE_PERIPHERAL_CONFIGURATION =
  "END_UPDATE_PERIPHERAL_CONFIGURATION";

const fetchPeripheralConfigurations = () => {
  return { type: FETCH_PERIPHERAL_CONFIGURATIONS, error: null };
};

const fetchPeripheralConfigurationsSucces = json => {
  return {
    type: FETCH_PERIPHERAL_CONFIGURATIONS_SUCCESS,
    peripheralConfigurations: json,
    error: null
  };
};

const fetchPeripheralConfigurationsFailure = error => {
  return { type: FETCH_PERIPHERAL_CONFIGURATIONS_FAILURE, error: error };
};

const createPeripheralConfigurationRequest = () => {
  return { type: CREATE_PERIPHERAL_CONFIGURATION, error: null };
};

const createPeripheralConfigurationSucces = json => {
  return {
    type: CREATE_PERIPHERAL_CONFIGURATION_SUCCESS,
    peripheralConfiguration: json,
    error: null
  };
};

const createPeripheralConfigurationFailure = error => {
  return { type: CREATE_PERIPHERAL_CONFIGURATION_FAILURE, error: error };
};

const updatePeripheralConfigurationRequest = () => {
  return { type: UPDATE_PERIPHERAL_CONFIGURATION, error: null };
};

const updatePeripheralConfigurationSucces = json => {
  return {
    type: UPDATE_PERIPHERAL_CONFIGURATION_SUCCESS,
    peripheralConfiguration: json,
    error: null
  };
};

const updatePeripheralConfigurationFailure = error => {
  return { type: UPDATE_PERIPHERAL_CONFIGURATION_FAILURE, error: error };
};

export const startUpdatePeripheralConfiguration = updateItem => {
  return {
    type: START_UPDATE_PERIPHERAL_CONFIGURATION,
    updateItem: updateItem
  };
};

export const startCreatePeripheralConfiguration = () => {
  return { type: START_CREATE_PERIPHERAL_CONFIGURATION };
};

export const endUpdatePeripheralConfiguration = () => {
  return { type: END_UPDATE_PERIPHERAL_CONFIGURATION };
};

export const endCreatePeripheralConfiguration = () => {
  return { type: END_CREATE_PERIPHERAL_CONFIGURATION };
};

export const fetchPeripheralConfigurationList = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchPeripheralConfigurations());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/configurations/peripheral_configurations`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchPeripheralConfigurationsSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchPeripheralConfigurationsFailure(error));
        }
      });
  };
};

export const createPeripheralConfiguration = params => {
  return (dispatch, getState) => {
    dispatch(createPeripheralConfigurationRequest());
    const user = getState().user.user;

    return axios
      .post(
        `${endPoint}/api/v1/configurations/peripheral_configurations`,
        params,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
      .then(response => {
        dispatch(createPeripheralConfigurationSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createPeripheralConfigurationFailure(error));
        }
      });
  };
};

export const updatePeripheralConfiguration = (
  peripheral_configuration,
  params
) => {
  return (dispatch, getState) => {
    dispatch(updatePeripheralConfigurationRequest());
    const user = getState().user.user;

    return axios
      .put(
        `${endPoint}/api/v1/configurations/peripheral_configurations/${peripheral_configuration.id}`,
        params,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
      .then(response => {
        dispatch(updatePeripheralConfigurationSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(updatePeripheralConfigurationFailure(error));
        }
      });
  };
};
