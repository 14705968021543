import URL from './config'
import uuidv1 from 'uuid/v1';
const authenticate = (username, password) => {

    return new Promise((resolve, reject) => {
        if (username == "test" && password == "test") {
            window.localStorage.setItem('token', uuidv1());
            return resolve();
        } else {
            return reject("User not valid!");
        }
    })
}

const isAuthenticated = () => {
    let user = window.localStorage.getItem('token');
    if (user) {
        return true;
    } else {
        return false;
    }
}

const logout = () => {
    return new Promise((resolve, reject) => {
        window.localStorage.removeItem('token');
        return resolve();
    })
}

export default { authenticate: authenticate, isAuthenticated: isAuthenticated, logout: logout } 