import axios from "axios";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_USER_GROUPS_REQUEST = "FETCH_USER_GROUPS_REQUEST";
const fetchUserGroupsRequest = () => {
  return { type: FETCH_USER_GROUPS_REQUEST };
};

export const FETCH_USER_GROUPS_SUCCES = "FETCH_USER_GROUPS_SUCCES";
const fetchUserGroupsSucess = json => {
  return { type: FETCH_USER_GROUPS_SUCCES, data: json.data, error: null };
};
export const FETCH_USER_GROUPS_SELECT_IF_NEEDED_SUCCES =
  "FETCH_USER_GROUPS_SELECT_IF_NEEDED_SUCCES";
const fetchUserGroupsForSelectIfNeededSucces = data => {
  return {
    type: FETCH_USER_GROUPS_SELECT_IF_NEEDED_SUCCES,
    data: data,
    error: null
  };
};

export const FETCH_USER_GROUPS_ERROR = "FETCH_USER_GROUPS_ERROR";
const fetchUserGroupsError = error => {
  return { type: FETCH_USER_GROUPS_ERROR, error: error };
};

export const fetchUserGroups = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchUserGroupsRequest());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/user_groups`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchUserGroupsSucess(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchUserGroupsError(error));
        }
      });
  };
};

export const fetchUserGroupsForSelectIfNeeded = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchUserGroupsRequest());
    const groups = getState().userGroups.items;
    const user = getState().user.user;
    if (groups != null) {
      dispatch(fetchUserGroupsForSelectIfNeededSucces(groups));
    } else {
      return axios
        .get(`${endPoint}/api/v1/user_groups`, {
          params: params,
          headers: { Authorization: `Bearer ${user.accessToken}` }
        })
        .then(response => {
          dispatch(fetchUserGroupsForSelectIfNeededSucces(response.data.data));
        })
        .catch(error => {
          if (checkErrorResponse(dispatch, user, error)) {
            dispatch(fetchUserGroupsError(error));
          }
        });
    }
  };
};
