import {
  FETCH_BUILDER_REQUEST,
  FETCH_BUILDER_SUCCES,
  FETCH_BUILDER_ERROR,
  CREATE_BUILD_REQUEST,
  CREATE_BUILD_SUCCES,
  CREATE_BUILD_ERROR,
  BUILD_START,
  BUILD_MESSAGE,
  BUILD_END_SUCCESS,
  BUILD_END_ERROR,
  CLEAR_BUILD_MESSAGES
} from "../actions/";

import { v1 as uuidv1 } from "uuid";

const builderReducer = (
  state = {
    isLoading: false,
    items: [],
    error: null,
    isCreating: false,
    isBuilding: false,
    messages: [],
    message: null,
    web_socket_id: uuidv1()
  },
  action
) => {
  switch (action.type) {
    case FETCH_BUILDER_REQUEST:
      return { ...state, isLoading: true, error: null, items: [], item: null };
    case FETCH_BUILDER_SUCCES:
      return {
        ...state,
        isLoading: false,
        items: action.builds,
        meta: action.meta
      };
    case FETCH_BUILDER_ERROR:
      return { ...state, isLoading: false, error: action.error, items: [] };
    case CREATE_BUILD_REQUEST:
      return { ...state, isCreating: true, error: null, item: null };
    case CREATE_BUILD_SUCCES:
      return {
        ...state,
        isCreating: false,
        error: null
      };
    case CREATE_BUILD_ERROR:
      return {
        ...state,
        error: action.error,
        item: null,
        isCreating: false
      };
    case BUILD_START:
      return { ...state, isBuilding: true, error: null, item: null };
    case BUILD_MESSAGE:
      return {
        ...state,
        messages: [
          {
            message: action.payload,
            channel: action.channel,
            messageType: action.messageType,
            timestamp: new Date()
          },
          ...state.messages
        ],
        message: action.payload,
        channel: action.channel,
        messageType: action.messageType
      };
    case BUILD_END_SUCCESS:
      return { ...state, isBuilding: false, error: null, item: null };
    case BUILD_END_ERROR:
      return { ...state, isBuilding: false, error: action.error, item: null };
    case CLEAR_BUILD_MESSAGES:
      return { ...state, messages: [] };
    default:
      return state;
  }
};

export default builderReducer;
