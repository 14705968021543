import {
    FETCH_PROTOCOL_ATTRIBUTE,
    FETCH_PROTOCOL_ATTRIBUTE_SUCCESS,
    FETCH_PROTOCOL_ATTRIBUTE_FAILURE,
} from '../actions/'

const protocolAttributeReducer = (state = { isLoading: false, attributes: {}, error: null }, action) => {
    switch (action.type) {
        case FETCH_PROTOCOL_ATTRIBUTE:
            return { ...state, isLoading: true, error: null,  protocolId: action.protocolId};
        case FETCH_PROTOCOL_ATTRIBUTE_SUCCESS:
            return { ...state, isLoading: false, items: action.attributes.data, attributes: {...state.attributes, [action.protocolId]: action.attributes.data}, protocolId: action.protocolId };
        case FETCH_PROTOCOL_ATTRIBUTE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}


export default protocolAttributeReducer