import apiState from "../apiState";
import {
  SET_ISLOADING,
  SET_ISNOTLOADING,
  SET_REPLY,
  SET_ATTRIBUTES,
  SET_ATTRIBUTES_VALUES,
  SET_ATTRIBUTE_VALUE,
  SET_EVENTS,
  SET_MESSAGES,
  SET_ATTRIBUTES_OUTPUT_VALUES,
  SET_EVENT_TO_TRIGGER
} from "../actionTypes";

const initialState = apiState;

const apis = (state = initialState, action) => {
  switch (action.type) {
    case SET_ISLOADING: {
      return { ...state, isLoading: true };
    }
    case SET_ISNOTLOADING: {
      return { ...state, isLoading: false };
    }
    case SET_REPLY: {
      return { ...state, apiReply: action.payload };
    }
    case SET_ATTRIBUTES: {
      return { ...state, attributes: action.payload };
    }
    case SET_ATTRIBUTES_VALUES: {
      const attributesInputState = {};
      action.payload.forEach(
        el => (attributesInputState[el.name] = el.default)
      );
      return { ...state, attributesInputState: attributesInputState };
    }
    case SET_ATTRIBUTE_VALUE: {
      const { attributesInputState } = state;
      attributesInputState[action.payload.name] = parseInt(
        action.payload.value
      );
      return { ...state, attributesInputState: attributesInputState };
    }
    case SET_ATTRIBUTES_OUTPUT_VALUES: {
      return { ...state, attributesOutputState: action.payload.attributesOutputState };
    }
    case SET_MESSAGES: {
      return { ...state, messages: action.payload.messages };
    }
    case SET_EVENTS: {
      return { ...state, events: action.payload };
    }
    case SET_EVENT_TO_TRIGGER: {
      return { ...state, eventToTrigger: action.payload };
    }
    default:
      return state;
  }
};

export default apis;
