import * as actions from "../actions";
import { Socket } from "phoenix";
import { deleteUser, tokenExpired } from "../../store/actions";

const phoenixWebSocket = () => {
  let socket = null;
  let phoenixChannels = [];

  const onOpen = store => event => {
    store.dispatch(actions.phoenixWebSocketConnect(event.target.url));
  };

  const onClose = store => () => {
    store.dispatch(actions.phoenixWebSocketDisconnected());
  };

  const onMessage = store => event => {
    const payload = JSON.parse(event.data);
    console.log("receiving server message");
    console.log(event.data);
    console.log(payload);
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case "PHOENIX_SOCKET_CONNECT":
        const user = store.getState().user.user;

        // connect to the remote host
        socket = new Socket(action.host, {
          params: { token: user.accessToken }
        });
        socket.connect();

        for (let index = 0; index < action.channels.length; index++) {
          const { channel, topics } = action.channels[index];

          let insertedIndex = phoenixChannels.push(socket.channel(channel, {}));
          insertedIndex -= 1;

          phoenixChannels[insertedIndex]
            .join()
            .receive("ok", () =>
              store.dispatch(actions.phoenixWebSocketJoinedChannel(channel))
            )
            .receive("error", ({ reason }) =>
              console.log("failed join", reason)
            )
            .receive("timeout", () =>
              console.log("Networking issue. Still waiting...")
            );

          for (let i = 0; i < topics.length; i++) {
            const topic = topics[i];
            phoenixChannels[insertedIndex].on(topic, payload => {
              if (topic == "token_expired") {
                store.dispatch(deleteUser());
                store.dispatch(tokenExpired());
              } else {
                console.log(payload);
                store.dispatch(
                  actions.phoenixWebSocketMessageFromChannel(
                    channel,
                    topic,
                    payload
                  )
                );
              }
            });
          }
        }
        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case "PHOENIX_SOCKET_DISCONNECT":
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.log("websocket closed");
        break;
      case "PHOENIX_SOCKET_PUSH_MESSAGE":
        const channel = phoenixChannels.find(
          phoenixChannel => phoenixChannel.topic == action.channel
        );
        if (channel) {
          channel.push(action.topic, action.message);
          store.dispatch(
            actions.phoenixWebSocketPushedMessage(
              action.channel,
              action.topic,
              action.message
            )
          );
        } else {
          store.dispatch(
            actions.phoenixWebSocketPushedMessageError(
              action.channel,
              action.topic,
              action.message,
              "Channel not exists"
            )
          );
        }

        break;
      default:
        console.log("the next action:", action);
        return next(action);
    }
  };
};

export default phoenixWebSocket();
