import {
    FETCH_VALUE_TYPE,
    FETCH_VALUE_TYPE_SUCCESS,
    FETCH_VALUE_TYPE_FAILURE,
} from '../actions/'

const valueTypeReducer = (state = { isLoading: false, items: [], error: null }, action) => {
    switch (action.type) {
        case FETCH_VALUE_TYPE:
            return { ...state, isLoading: true, error: null};
        case FETCH_VALUE_TYPE_SUCCESS:
            return { ...state, isLoading: false, items: action.items.data,};
        case FETCH_VALUE_TYPE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}


export default valueTypeReducer