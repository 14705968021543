import URL from './config'

const fetchValueTypesPage = () => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/value_types`)
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{
              resolve(result.data);
          }           
        })
      });
}


export default {fetchValueTypesPage: fetchValueTypesPage}