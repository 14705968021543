import {
    FETCH_PERIPHERAL_CONFIGURATIONS,
    FETCH_PERIPHERAL_CONFIGURATIONS_FAILURE,
    FETCH_PERIPHERAL_CONFIGURATIONS_SUCCESS,
    CREATE_PERIPHERAL_CONFIGURATION,
    CREATE_PERIPHERAL_CONFIGURATION_SUCCESS,
    CREATE_PERIPHERAL_CONFIGURATION_FAILURE,
    UPDATE_PERIPHERAL_CONFIGURATION,
    UPDATE_PERIPHERAL_CONFIGURATION_SUCCESS,
    UPDATE_PERIPHERAL_CONFIGURATION_FAILURE,
    START_CREATE_PERIPHERAL_CONFIGURATION,
    START_UPDATE_PERIPHERAL_CONFIGURATION,
    END_UPDATE_PERIPHERAL_CONFIGURATION,
    END_CREATE_PERIPHERAL_CONFIGURATION
} from '../actions/'

const peripheralConfigurationReducer = (state = { isLoading: false, items: [], error: null, isCreating: false, isUpdating: false }, action) => {
    switch (action.type) {
        case FETCH_PERIPHERAL_CONFIGURATIONS:
            return { ...state, isLoading: true, error: null, items: [] };
        case FETCH_PERIPHERAL_CONFIGURATIONS_SUCCESS:
            return { ...state, isLoading: false, items: action.peripheralConfigurations.data, meta: action.peripheralConfigurations.meta, error: null };
        case FETCH_PERIPHERAL_CONFIGURATIONS_FAILURE:
            return { ...state, isLoading: false, error: action.error, items: [] };
        case CREATE_PERIPHERAL_CONFIGURATION:
            return { ...state, isCreateLoading: true, error: null, item: null }
        case CREATE_PERIPHERAL_CONFIGURATION_SUCCESS:
            return { ...state, isCreateLoading: false, error: null, items: [...state.items, action.peripheralConfiguration.data], item: action.peripheralConfiguration.data, isCreating: false }
        case CREATE_PERIPHERAL_CONFIGURATION_FAILURE:
            return { ...state, isCreateLoading: false, error: action.error, item: null, isCreating: false }
        case UPDATE_PERIPHERAL_CONFIGURATION:
            return { ...state, isLoading: true, error: null, item: null  }
        case UPDATE_PERIPHERAL_CONFIGURATION_SUCCESS:
            return { ...state, isLoading: false, error: null, item: action.peripheralConfiguration.data, items: findAndReplace(state.items, action.peripheralConfiguration.data), isUpdating: false, updateItem: null }
        case UPDATE_PERIPHERAL_CONFIGURATION_FAILURE:
            return { ...state, isLoading: false, error: action.error, item: null, isUpdating: false  }
        case START_UPDATE_PERIPHERAL_CONFIGURATION:
            return { ...state, isUpdating: true , updateItem: action.updateItem }
        case START_CREATE_PERIPHERAL_CONFIGURATION: 
            return { ...state, isCreating: true, updateItem: null  }
        case END_UPDATE_PERIPHERAL_CONFIGURATION:
            return { ...state, isUpdating: false }
        case END_CREATE_PERIPHERAL_CONFIGURATION: 
            return { ...state, isCreating: false, updateItem: null  }
        default:
            return state;
    }
}

const findAndReplace = (items, newItem) => {
    const index = items.findIndex(i => i.id == newItem.id)
    if(index == -1){
        return [...items, newItem]
    }
    
    return items.map((element, i) => i == index ? newItem : element)
}
export default peripheralConfigurationReducer