import {
  CREATE_IMAGE,
  CREATE_IMAGE_SUCCESS,
  CREATE_IMAGE_FAILURE,
  FETCH_IMAGE,
  FETCH_IMAGE_SUCCESS,
  FETCH_IMAGE_FAILURE,
  CLEAR_IMAGE
} from "../actions/";

const imageReducer = (
  state = { error: null, isLoading: false, item: null },
  action
) => {
  switch (action.type) {
    case CREATE_IMAGE:
      return { ...state, isLoading: true, error: null, item: null };
    case CREATE_IMAGE_SUCCESS:
      return { ...state, isLoading: false, error: null, item: action.image };
    case CREATE_IMAGE_FAILURE:
      return { ...state, isLoading: false, error: action.error, item: null };
    case FETCH_IMAGE:
      return { ...state, isLoading: true, error: null, item: null };
    case FETCH_IMAGE_SUCCESS:
      return { ...state, isLoading: false, error: null, item: action.image };
    case FETCH_IMAGE_FAILURE:
      return { ...state, isLoading: false, error: action.error, item: null };
    case CLEAR_IMAGE:
      return { ...state, isLoading: false, error: null, item: null };
    default:
      return state;
  }
};

export default imageReducer;
