import {
  FETCH_PROTOCOL_ACTION,
  FETCH_PROTOCOL_ACTION_SUCCESS,
  FETCH_PROTOCOL_ACTION_FAILURE
} from "../actions/";

const protocolActionReducer = (
  state = { isLoading: false, attributes: null, error: null },
  action
) => {
  switch (action.type) {
    case FETCH_PROTOCOL_ACTION:
      return {
        ...state,
        isLoading: true,
        error: null,
        protocolId: action.protocolId
      };
    case FETCH_PROTOCOL_ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.attributes.data,
        attributes: {
          ...state.attributes,
          [action.protocolId]: action.attributes.data
        },
        protocolId: action.protocolId
      };
    case FETCH_PROTOCOL_ACTION_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default protocolActionReducer;
