import URL from './config'

let buildQueryParams = (page = 1, name = '', device_class = '') => {
    let queryParams = `page=${page}`
    if (name != "") {
        queryParams = queryParams + "&name=${name}"
    }
    if (device_class != "") {
        queryParams = queryParams + "&device_class=${device_class}"
    }
    return queryParams
}

let buildObjectQueryParams = (params = {}) => {
    return Object.keys(params).map((k) => `${k}=${params[k]}`).join("&")
}

const fetchPeripheralConfigurationsPage = (page = 1, name = '', device_class = '') => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/configurations/peripheral_configurations?${buildQueryParams(page, name, device_class)}`)
            .then(res => {
                if (res.status > 300) {
                    return {
                        error: {
                            statusCode: res.status,
                            reason: res.statusText
                        }
                    }
                } else {
                    return res.json()
                }
            })
            .then(result => {
                if (result.error) {
                    console.log(result.errors)
                    reject(result.error);
                } else {
                    resolve(result.data);
                }
            })
    });
}

const fetchPeripheralConfigurationsPageWithParams = (parameters = {}) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/configurations/peripheral_configurations?${buildObjectQueryParams(parameters)}`)
            .then(res => {
                if (res.status > 300) {
                    return {
                        error: {
                            statusCode: res.status,
                            reason: res.statusText
                        }
                    }
                } else {
                    return res.json()
                }
            })
            .then(result => {
                if (result.error) {
                    console.log(result.errors)
                    reject(result.error);
                } else {
                    resolve(result.data);
                }
            })
    });
}

const createPeripheralConfiguration = (peripheral_configuration) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/configurations/peripheral_configurations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "peripheral_configuration": peripheral_configuration
            })
        })
            .then(res => {
                if (res.status > 300) {
                    return {
                        error: {
                            statusCode: res.status,
                            reason: res.statusText
                        }
                    }
                } else {
                    return res.json()
                }
            })
            .then(result => {
                if (result.error) {
                    console.log(result.errors)
                    reject(result.error);
                } else {
                    resolve(result.data);
                }
            })
            .catch((error) => {
                reject(`${error}`);
            })

    })
}

export default { fetchPeripheralConfigurationsPageWithParams: fetchPeripheralConfigurationsPageWithParams, fetchPeripheralConfigurationsPage: fetchPeripheralConfigurationsPage, createPeripheralConfiguration: createPeripheralConfiguration }