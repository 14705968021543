import {
  ON_DRAG_NODE,
  ON_DRAG_CANVAS,
  ON_LINK_START,
  ON_LINK_MOVE,
  ON_LINK_COMPLETE,
  ON_LINK_CANCEL,
  ON_LINK_MOUSE_ENTER,
  ON_LINK_MOUSE_LEAVE,
  ON_LINK_CLICK,
  ON_CANVAS_CLICK,
  ON_DELETE_KEY,
  ON_NODE_CLICK,
  ON_NODE_SIZE_CHANGE,
  ON_PORT_POSITION_CHANGE,
  ON_CANVAS_DROP,
  DUMP_STATE,
  ON_INPUT_CHANGE,
  LOAD_JSON_CHART,
  SET_JSON_CHART,
  ADD_NEW_RECORD,
  SET_ENVVARS,
  DEL_ENVVAR,
  ADD_ENVVAR,
  SET_METADATA,
  SET_ISLOADING,
  SET_ISNOTLOADING,
  SET_ATTRIBUTES,
  SET_ATTRIBUTES_VALUES,
  SET_ATTRIBUTE_VALUE,
  SET_EVENTS,
  SET_MESSAGES,
  SET_ATTRIBUTES_OUTPUT_VALUES,
  SET_EVENT_TO_TRIGGER,
  REQUEST_START_ROBO_SWARM,
  ROBO_SWARM_STARTED,
  ROBO_SWARM_STARTED_ERROR,
  REQUEST_STATUS_ROBO_SWARM,
  ROBO_SWARM_STATUS,
  REQUEST_STOP_ROBO_SWARM,
  ROBO_SWARM_STOPPED,
  REQUEST_CONNECTION_TO_WEB_SOCKET,
  REQUEST_DISCONNETING_FROM_WEB_SOCKET,
  SEND_MESSAGE_TO_WEB_SOCKET,
  CHART_SAVE
} from "./actionTypes";
import axios from "axios";
import { connect, disconnect, send } from "@giantmachines/redux-websocket";
import {
  applicationDebugStarted,
  applicationDebugInitEnd,
  applicationDebugReceiveMessage
} from "./actions/applicationDebugger";
import {
  BUILD_MESSAGE,
  BUILD_END_SUCCESS,
  CREATE_DEVICE_TYPE_OBJECT_SUCCESS
} from "../store/actions";
export { consolePushLog } from "./actions/consoleLog";
export * from "./actions/applicationDebugger";
export * from "./actions/consoleLog";
export * from "./actions/rightSideMenu";

const endPoint = process.env.REACT_APP_BASE_URL;
const endPointRoboSwarm = process.env.REACT_APP_ROBO_SWARM_BASE_URL;

export const onDragNode = content => ({
  type: ON_DRAG_NODE,
  payload: content
});

export const onDragCanvas = content => ({
  type: ON_DRAG_CANVAS,
  payload: content
});

export const onLinkStart = content => ({
  type: ON_LINK_START,
  payload: content
});

export const onLinkMove = content => ({
  type: ON_LINK_MOVE,
  payload: content
});

export const onLinkComplete = content => ({
  type: ON_LINK_COMPLETE,
  payload: content
});

export const onLinkCancel = content => ({
  type: ON_LINK_CANCEL,
  payload: content
});

export const onLinkMouseEnter = content => ({
  type: ON_LINK_MOUSE_ENTER,
  payload: content
});

export const onLinkMouseLeave = content => ({
  type: ON_LINK_MOUSE_LEAVE,
  payload: content
});

export const onLinkClick = content => ({
  type: ON_LINK_CLICK,
  payload: content
});

export const onCanvasClick = content => ({
  type: ON_CANVAS_CLICK,
  payload: content
});

export const onDeleteKey = content => ({
  type: ON_DELETE_KEY,
  payload: content
});

export const onNodeClick = content => ({
  type: ON_NODE_CLICK,
  payload: content
});

export const onNodeSizeChange = content => ({
  type: ON_NODE_SIZE_CHANGE,
  payload: content
});

export const onPortPositionChange = content => ({
  type: ON_PORT_POSITION_CHANGE,
  payload: content
});

export const onInputChange = content => ({
  type: ON_INPUT_CHANGE,
  payload: content
});

export const onCanvasDrop = content => {
  console.log(content);
  return {
    type: ON_CANVAS_DROP,
    payload: content
  };
};

export const dumpState = args => ({
  type: DUMP_STATE,
  payload: null
});

export const loadJsonChart = args => ({
  type: LOAD_JSON_CHART,
  payload: args
});

export const setJsonChart = altiorApplication => ({
  type: SET_JSON_CHART,
  payload: altiorApplication
});

export const addNewRecord = record => ({
  type: ADD_NEW_RECORD,
  payload: record
});

export const setEnvVars = envvars => ({
  type: SET_ENVVARS,
  payload: envvars
});

export const addEnvVar = envvar => ({
  type: ADD_ENVVAR,
  payload: envvar
});

export const delEnvVar = envvarName => ({
  type: DEL_ENVVAR,
  payload: envvarName
});

export const setMetadata = metadata => ({
  type: SET_METADATA,
  payload: metadata
});

export const chartSave = () => ({
  type: CHART_SAVE
});

// APi

export const apisCall = url => {
  return (dispatch, getState) => {
    const user = getState().user.user;
    dispatch({ type: SET_ISLOADING });
    axios
      .get(url, { headers: { Authorization: `Bearer ${user.accessToken}` } })
      .then(resp => {
        return resp.data;
      })
      .then(resp => {
        Promise.all([
          axios.get(
            `${endPoint}/api/v1/protocols/${resp.data.device_type.protocol_id}/attributes`,
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
          ),
          axios.get(
            `${endPoint}/api/v1/protocols/${resp.data.device_type.protocol_id}/actions`,
            {
              headers: { Authorization: `Bearer ${user.accessToken}` }
            }
          )
        ]).then(values => {
          const attributes = values[0];
          const actions = values[1];

          dispatch({ type: SET_ATTRIBUTES, payload: attributes.data.data });
          dispatch({
            type: SET_ATTRIBUTES_VALUES,
            payload: attributes.data.data
          });

          dispatch({ type: SET_EVENTS, payload: actions.data.data });
          dispatch({
            type: SET_EVENT_TO_TRIGGER,
            payload: actions.data.data[0].name
          });

          dispatch({ type: SET_ISNOTLOADING, payload: null });
        });
      })
      .catch(err => dispatch({ type: SET_ISNOTLOADING, payload: err.data }));
  };
};

export const setAttributeValue = nameAndValue => ({
  type: SET_ATTRIBUTE_VALUE,
  payload: nameAndValue
});

export const setEventToTrigger = eventToTrigger => ({
  type: SET_EVENT_TO_TRIGGER,
  payload: eventToTrigger
});

export const setMessages = messages => ({
  type: SET_MESSAGES,
  payload: messages
});
export const setAttributesOutputState = outputValues => ({
  type: SET_ATTRIBUTES_OUTPUT_VALUES,
  payload: outputValues
});

export const requestStartRoboSwarm = params => {
  return {
    type: REQUEST_START_ROBO_SWARM,
    exchange_name: params.exchange_name,
    gateway_number: params.gateway_number,
    meter_number: params.meter_number
  };
};

export const roboSwarmStarted = () => {
  return { type: ROBO_SWARM_STARTED };
};

export const roboSwarmStartedError = error => {
  return { type: ROBO_SWARM_STARTED_ERROR, error: error };
};

export const requestStopRoboSwarm = () => {
  return { type: REQUEST_STOP_ROBO_SWARM };
};

export const roboSwarmStopped = () => {
  return { type: ROBO_SWARM_STOPPED };
};

export const requestStatusRoboSwarm = (params = {}) => {
  return {
    type: REQUEST_STATUS_ROBO_SWARM,
    exchange_name: params.exchange_name,
    gateway_number: params.gateway_number,
    meter_number: params.meter_number
  };
};

export const roboSwarmStatus = status => {
  return { type: ROBO_SWARM_STATUS, status: status };
};

export const startRoboSwarm = params => {
  return (dispatch, getState) => {
    const user = getState().user.user;
    dispatch(requestStartRoboSwarm(params));
    return axios
      .post(`${endPointRoboSwarm}/robo_swarm`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(roboSwarmStarted(response.data));
      })
      .catch(error => {
        dispatch(roboSwarmStartedError(error.message));
      });
  };
};

export const reStartRoboSwarm = params => {
  return (dispatch, getState) => {
    const user = getState().user.user;
    dispatch(requestStartRoboSwarm(params));
    return axios
      .post(`${endPointRoboSwarm}/robo_swarm/restart`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(roboSwarmStarted(response.data));
      })
      .catch(error => {
        dispatch(roboSwarmStartedError(error.message));
      });
  };
};

export const stopRoboSwarm = () => {
  return (dispatch, getState) => {
    const user = getState().user.user;
    dispatch(requestStopRoboSwarm());
    return axios
      .delete(`${endPointRoboSwarm}/robo_swarm/running`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(roboSwarmStopped());
      })
      .catch(error => {
        dispatch(roboSwarmStartedError(error.message));
      });
  };
};

export const statusRoboSwarm = () => {
  return (dispatch, getState) => {
    const user = getState().user.user;
    dispatch(requestStatusRoboSwarm(getState().robo_swarm));
    return axios
      .get(`${endPointRoboSwarm}/robo_swarm/running`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(roboSwarmStatus(response));
      })
      .catch(error => {
        dispatch(roboSwarmStartedError(error.message));
      });
  };
};

export const requestConnectionToWebSocket = url => {
  return { type: REQUEST_CONNECTION_TO_WEB_SOCKET, url: url };
};

export const requestDisconnetingFromWebSocket = () => {
  return { type: REQUEST_DISCONNETING_FROM_WEB_SOCKET };
};

export const sendMessageToWebSocket = message => {
  return { type: SEND_MESSAGE_TO_WEB_SOCKET, message: message };
};

export const connectToWebSocket = url => {
  return (dispatch, getState) => {
    const user = getState().user.user;
    dispatch(connect(url + `?token=${user.accessToken}`));
  };
};

export const disconnectFromWebSocket = () => {
  return dispatch => {
    dispatch(disconnect());
  };
};

export const sendToWebSocket = message => {
  return dispatch => {
    dispatch(send(message));
  };
};

export const phoenixWebSocketConnect = (host, channels) => ({
  type: "PHOENIX_SOCKET_CONNECT",
  host,
  channels
});
export const phoenixWebSocketConnecting = host => ({
  type: "PHOENIX_SOCKET_CONNECTING",
  host
});
export const phoenixWebSocketConnected = host => ({
  type: "PHOENIX_SOCKET_CONNECTED",
  host
});
export const phoenixWebSocketDisconnect = host => ({
  type: "PHOENIX_SOCKET_DISCONNECT",
  host
});
export const phoenixWebSocketDisconnected = host => ({
  type: "PHOENIX_SOCKET_DISCONNECTED",
  host
});

export const phoenixWebSocketJoinedChannel = channel => {
  return dispatch => {
    if (channel == process.env.REACT_APP_FLOWCHART_WS_CHANNEL) {
      dispatch(applicationDebugInitEnd());
    } else {
      dispatch({
        type: "PHOENIX_SOCKET_JOINED_CHANNEL",
        channel
      });
    }
  };
};

export const phoenixWebSocketMessageFromChannel = (
  channel,
  messageType,
  payload
) => {
  return (dispatch, getState) => {
    switch (channel) {
      case process.env.REACT_APP_FLOWCHART_WS_CHANNEL:
        if (getState().applicationDebugger.isCompiling) {
          dispatch(applicationDebugStarted({ channel, messageType, payload }));
        } else {
          dispatch(
            applicationDebugReceiveMessage(channel, messageType, payload)
          );
        }
        break;

      default:
        if (channel.includes(process.env.REACT_APP_BUILDER_WS_CHANNEL)) {
          dispatch({
            type: BUILD_MESSAGE,
            channel,
            messageType,
            payload
          });
          if (messageType == "done") {
            dispatch({
              type: BUILD_END_SUCCESS
            });
          }
        } else {
          if (
            channel.includes(process.env.REACT_APP_CREATE_OBJECT_WS_CHANNEL)
          ) {
            dispatch({
              type: CREATE_DEVICE_TYPE_OBJECT_SUCCESS,
              channel,
              messageType,
              payload
            });
          } else {
            dispatch({
              type: "PHOENIX_SOCKET_MESSAGE_FROM_CHANNEL",
              channel,
              messageType,
              payload
            });
          }
        }
        break;
    }
    // if (channel == process.env.REACT_APP_FLOWCHART_WS_CHANNEL) {
    //   if (getState().applicationDebugger.isCompiling) {
    //     dispatch(applicationDebugStarted({ channel, messageType, payload }));
    //   } else {
    //     dispatch(applicationDebugReceiveMessage(channel, messageType, payload));
    //   }
    // } else {
    //   dispatch({
    //     type: "PHOENIX_SOCKET_MESSAGE_FROM_CHANNEL",
    //     channel,
    //     messageType,
    //     payload
    //   });
    // }
  };
};

// export const phoenixWebSocketMessageFromChannel = (
//   channel,
//   messageType,
//   payload
// ) => ({
//   type: "PHOENIX_SOCKET_MESSAGE_FROM_CHANNEL",
//   channel,
//   messageType,
//   payload
// });

export const phoenixWebSocketPushMessage = (channel, topic, message) => ({
  type: "PHOENIX_SOCKET_PUSH_MESSAGE",
  channel,
  topic,
  message
});

export const phoenixWebSocketPushedMessage = (channel, topic, message) => {
  return dispatch => {
    dispatch({
      type: "PHOENIX_SOCKET_PUSHED_MESSAGE",
      channel,
      topic,
      message
    });
  };
};

export const phoenixWebSocketPushedMessageError = (
  channel,
  topic,
  message,
  error
) => {
  return dispatch => {
    dispatch({
      type: "PHOENIX_SOCKET_PUSHED_MESSAGE_ERROR",
      channel,
      topic,
      message,
      error
    });
    //TODO: mandare al debug
    // dispatch(consolePushLog({ channel, topic, message, error }));
  };
};
