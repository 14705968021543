import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_DEVICE_TYPES = "FETCH_DEVICE_TYPES";
export const FETCH_DEVICE_TYPES_SUCCESS = "FETCH_DEVICE_TYPES_SUCCESS";
export const FETCH_DEVICE_TYPES_FAILURE = "FETCH_DEVICE_TYPES_FAILURE";
export const CREATE_DEVICE_TYPE = "CREATE_DEVICE_TYPE";
export const CREATE_DEVICE_TYPE_SUCCESS = "CREATE_DEVICE_TYPE_SUCCESS";
export const CREATE_DEVICE_TYPE_FAILURE = "CREATE_DEVICE_TYPE_FAILURE";
export const UPDATE_DEVICE_TYPE = "UPDATE_DEVICE_TYPE";
export const UPDATE_DEVICE_TYPE_SUCCESS = "UPDATE_DEVICE_TYPE_SUCCESS";
export const UPDATE_DEVICE_TYPE_FAILURE = "UPDATE_DEVICE_TYPE_FAILURE";
export const START_UPDATE_DEVICE_TYPE = "START_UPDATE_DEVICE_TYPE";
export const START_CREATE_DEVICE_TYPE = "START_CREATE_DEVICE_TYPE";
export const END_UPDATE_DEVICE_TYPE = "END_UPDATE_DEVICE_TYPE";
export const END_CREATE_DEVICE_TYPE = "END_CREATE_DEVICE_TYPE";
export const FETCH_DEVICE_TYPE = "FETCH_DEVICE_TYPE";
export const FETCH_DEVICE_TYPE_SUCCESS = "FETCH_DEVICE_TYPE_SUCCESS";
export const FETCH_DEVICE_TYPE_FAILURE = "FETCH_DEVICE_TYPE_FAILURE";
export const CLEAR_CURRENT_DEVICE_TYPE = "CLEAR_CURRENT_DEVICE_TYPE";
export const CREATE_IMAGE = "CREATE_IMAGE";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";
export const CREATE_IMAGE_FAILURE = "CREATE_IMAGE_FAILURE";

const fetchDeviceTypes = () => {
  return { type: FETCH_DEVICE_TYPES, error: null };
};

const fetchDeviceTypesSucces = json => {
  return { type: FETCH_DEVICE_TYPES_SUCCESS, deviceTypes: json, error: null };
};

const fetchDeviceTypesFailure = error => {
  return { type: FETCH_DEVICE_TYPES_FAILURE, error: error };
};

const createDeviceTypeRequest = () => {
  return { type: CREATE_DEVICE_TYPE, error: null };
};

const createDeviceTypeSucces = json => {
  return { type: CREATE_DEVICE_TYPE_SUCCESS, deviceType: json, error: null };
};

const createDeviceTypeFailure = error => {
  return { type: CREATE_DEVICE_TYPE_FAILURE, error: error };
};

const updateDeviceTypeRequest = () => {
  return { type: UPDATE_DEVICE_TYPE, error: null };
};

const updateDeviceTypeSucces = json => {
  return { type: UPDATE_DEVICE_TYPE_SUCCESS, deviceType: json, error: null };
};

const updateDeviceTypeFailure = error => {
  return { type: UPDATE_DEVICE_TYPE_FAILURE, error: error };
};

export const startUpdateDeviceType = updateItem => {
  return { type: START_UPDATE_DEVICE_TYPE, updateItem: updateItem };
};

export const startCreateDeviceType = () => {
  return { type: START_CREATE_DEVICE_TYPE };
};

export const endUpdateDeviceType = () => {
  return { type: END_UPDATE_DEVICE_TYPE };
};

export const endCreateDeviceType = () => {
  return { type: END_CREATE_DEVICE_TYPE };
};

export const clearCurrentDeviceType = () => {
  return { type: CLEAR_CURRENT_DEVICE_TYPE };
};

export const createImage = image => {
  return { type: CREATE_IMAGE, image: null };
};
export const createImageSuccess = image => {
  return { type: CREATE_IMAGE_SUCCESS, image: image };
};
export const createImageFailuer = error => {
  return { type: CREATE_IMAGE_FAILURE, error: error, image: null };
};

export const fetchDeviceTypeList = (params = {}, callback = null) => {
  return (dispatch, getState) => {
    dispatch(fetchDeviceTypes());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/device_types`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchDeviceTypesSucces(response.data));
        if (callback) {
          prepareForSelect(response.data.data, callback);
        }
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchDeviceTypesFailure(error));
        }
      });
  };
};

const prepareForSelect = (data, callback) => {
  callback(
    data.map(item => {
      return { label: `${item.name} (${item.version})`, value: item };
    })
  );
};

export const fetchDeviceType = id => {
  return { type: FETCH_DEVICE_TYPE, error: null, id: id };
};

export const fetchDeviceTypeSucces = json => {
  return { type: FETCH_DEVICE_TYPE_SUCCESS, deviceType: json, error: null };
};

export const fetchDeviceTypeFailure = error => {
  return { type: FETCH_DEVICE_TYPE_FAILURE, error: error };
};

export const createDeviceType = params => {
  return (dispatch, getState) => {
    dispatch(createDeviceTypeRequest());
    const user = getState().user.user;
    if (params.device_type.image != "") {
      dispatch(createImage());
      const data = new FormData();
      data.append("image", params.device_type.image);
      return axios
        .post(`${endPoint}/api/v1/images`, data, {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        })
        .then(response => {
          dispatch(createImageSuccess(response.data.data));
          delete params.device_type.image;
          params.device_type["image_id"] = response.data.data.id;
          return createDeviceTypeApiCall(dispatch, params, user);
        })
        .catch(error => {
          if (checkErrorResponse(dispatch, user, error)) {
            dispatch(createImageFailuer(error));
          }
        });
    } else {
      return createDeviceTypeApiCall(dispatch, params, user);
    }
  };
};

const createDeviceTypeApiCall = (dispatch, params, user) => {
  return axios
    .post(`${endPoint}/api/v1/device_types`, params, {
      headers: { Authorization: `Bearer ${user.accessToken}` }
    })
    .then(response => {
      dispatch(createDeviceTypeSucces(response.data));
    })
    .catch(error => {
      if (checkErrorResponse(dispatch, user, error)) {
        dispatch(createDeviceTypeFailure(error));
      }
    });
};

export const updateDeviceType = (deviceType, params) => {
  return (dispatch, getState) => {
    dispatch(updateDeviceTypeRequest());
    const user = getState().user.user;
    if (params.device_type.image != "") {
      dispatch(createImage());
      const data = new FormData();
      data.append("image", params.device_type.image);
      return axios
        .post(`${endPoint}/api/v1/images`, data, {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        })
        .then(response => {
          dispatch(createImageSuccess(response.data.data));
          delete params.device_type.image;
          params.device_type["image_id"] = response.data.data.id;
          return updateDeviceTypeApiCall(dispatch, deviceType, params, user);
        })
        .catch(error => {
          if (checkErrorResponse(dispatch, user, error)) {
            dispatch(createImageFailuer(error));
          }
        });
    } else {
      return updateDeviceTypeApiCall(dispatch, deviceType, params, user);
    }
  };
};

const updateDeviceTypeApiCall = (dispatch, deviceType, params, user) => {
  return axios
    .put(`${endPoint}/api/v1/device_types/${deviceType.id}`, params, {
      headers: { Authorization: `Bearer ${user.accessToken}` }
    })
    .then(response => {
      dispatch(updateDeviceTypeSucces(response.data));
    })
    .catch(error => {
      if (checkErrorResponse(dispatch, user, error)) {
        dispatch(updateDeviceTypeFailure(error));
      }
    });
};

export const showDeviceType = id => {
  return (dispatch, getState) => {
    dispatch(fetchDeviceType(id));
    const user = getState().user.user;

    return axios
      .get(`${endPoint}/api/v1/device_types/${id}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchDeviceTypeSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchDeviceTypeFailure(error));
        }
      });
  };
};
