import URL from './config'

const fetchObjectsPage = (device_types_id, page = 1) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types/${device_types_id}/objects?page=${page}`)
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{
              resolve(result.data);
          }
        })
      });
}

const showObject = (device_types_id = 1, id = 1) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types/${device_types_id}/objects/${id}`)
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{

              resolve(result);
          }
        })
      });
}

const createObject = (device_types_id, object) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types/${device_types_id}/objects/`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
             "object": object
            })
        })
        .then(res => {
            if(res.status > 300){
                return {
                    error: {
                        statusCode: res.status,
                        reason: res.statusText
                    }
                }
            }else{
                return res.json()
            }
        })
        .then(result => {
            if(result.error) {
                console.log(result)
                reject(result.error);
            }else{
                resolve(result.data);
            }
        })
        .catch((error) =>{
            reject(`${error}`);
          })

    })
}

export default {fetchObjectsPage: fetchObjectsPage, showObject: showObject, createObject: createObject}
