import URL from "./config";

const showFlowChart = (altiorApplicationId) => {    
  return new Promise((resolve, reject) => {
    fetch(
      `${URL.BASE_URL}/api/v1/altior_applications/${altiorApplicationId}`
    )
      .then(res => {
        if (res.status > 300) {
          return {
            error: {
              statusCode: res.status,
              reason: res.statusText
            }
          };
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.error) {
          console.log(result.errors);
          reject(result.error);
        } else {
          resolve(result.data);
        }
      });
  });
}


const fetchFlowChartsPage = (page = 1, name = "") => {
  return new Promise((resolve, reject) => {
    fetch(
      `${URL.BASE_URL}/api/v1/altior_applications?page=${page}`
    )
      .then(res => {
        if (res.status > 300) {
          return {
            error: {
              statusCode: res.status,
              reason: res.statusText
            }
          };
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.error) {
          console.log(result.errors);
          reject(result.error);
        } else {
          resolve(result.data);
        }
      });
  });
};

const updateFlowChart = (altiorApplicationId, body) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL.BASE_URL}/api/v1/altior_applications/${altiorApplicationId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        altior_application: body
      })
    })
      .then(res => {
        if (res.status > 300) {
          return {
            error: {
              statusCode: res.status,
              reason: res.statusText
            }
          };
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.error) {
          console.log(result.errors);
          reject(result.error);
        } else {
          resolve(result.data);
        }
      })
      .catch(error => {
        reject(`${error}`);
      });
  });
};

const createFlowChart = flowchart => {
  return new Promise((resolve, reject) => {
    fetch(`${URL.BASE_URL}/api/v1/altior_applications`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        altior_application: flowchart
      })
    })
      .then(res => {
        if (res.status > 300) {
          return {
            error: {
              statusCode: res.status,
              reason: res.statusText
            }
          };
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.error) {
          console.log(result.errors);
          reject(result.error);
        } else {
          resolve(result.data);
        }
      })
      .catch(error => {
        reject(`${error}`);
      });
  });
};

export default {
  fetchFlowChartsPage: fetchFlowChartsPage,
  createFlowChart: createFlowChart,
  showFlowChart: showFlowChart,
  updateFlowChart: updateFlowChart
};
