import {
  REQUEST_START_ROBO_SWARM,
  ROBO_SWARM_STARTED,
  ROBO_SWARM_STARTED_ERROR,
  REQUEST_STATUS_ROBO_SWARM,
  ROBO_SWARM_STATUS,
  REQUEST_STOP_ROBO_SWARM,
  ROBO_SWARM_STOPPED
} from "../actionTypes";

const protocolReducer = (
  state = { isRunning: false, isLoading: false },
  action
) => {
  switch (action.type) {
    case REQUEST_START_ROBO_SWARM:
      return {
        ...state,
        isLoading: true,
        exchange_name: action.exchange_name,
        gateway_number: action.gateway_number,
        meter_number: action.meter_number
      };
    case ROBO_SWARM_STARTED:
      return { ...state, isLoading: false, isRunning: true, error: null};
    case ROBO_SWARM_STARTED_ERROR:
      return {
        ...state,
        isLoading: false,
        isRunning: false,
        error: action.error
      };
    case REQUEST_STATUS_ROBO_SWARM:
      return { ...state, isLoading: true, error: null};
    case ROBO_SWARM_STATUS:
      return {
        ...state,
        isLoading: false,
        isRunning: action.status.data.is_running,
        error: null
      };
    case REQUEST_STOP_ROBO_SWARM:
      return { ...state, isLoading: true };
    case ROBO_SWARM_STOPPED:
      return { ...state, isLoading: false, isRunning: false };
    default:
      return state;
  }
};
export default protocolReducer;
