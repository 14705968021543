const apiState = {
  isLoading: false,
  attributes: [],
  events: [],
  attributesInputState: {}, // Only the pair name-value for the debugger
  attributesOutputState: {},
  eventToTrigger: undefined, // The event to send
  messages: [],
};

export default apiState;
