import { tokenExpired } from "../store/actions/token";
import { deleteUser } from "../store/actions/user";
export const checkErrorResponse = (dispatch, user, error) => {
  if (error.response && error.response.status == 401 && user.accessToken) {
    dispatch(deleteUser());
    dispatch(tokenExpired());
    return false;
  } else {
    return true;
  }
};
