import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_NETWORK_PROTOCOLRS = "FETCH_NETWORK_PROTOCOLRS";
export const FETCH_NETWORK_PROTOCOLRS_SUCCESS =
  "FETCH_NETWORK_PROTOCOLRS_SUCCESS";
export const FETCH_NETWORK_PROTOCOLRS_FAILURE =
  "FETCH_NETWORK_PROTOCOLRS_FAILURE";
export const START_CREATE_NETWORK_PROTOCOLR = "START_CREATE_NETWORK_PROTOCOLR";
export const CREATE_NETWORK_PROTOCOLR = "CREATE_NETWORK_PROTOCOLR";
export const CREATE_NETWORK_PROTOCOLR_SUCCESS =
  "CREATE_NETWORK_PROTOCOLR_SUCCESS";
export const CREATE_NETWORK_PROTOCOLR_FAILURE =
  "CREATE_NETWORK_PROTOCOLR_FAILURE";
export const END_CREATE_NETWORK_PROTOCOLR = "END_CREATE_NETWORK_PROTOCOLR";
export const START_UPDATE_NETWORK_PROTOCOLR = "START_UPDATE_NETWORK_PROTOCOLR";
export const UPDATE_NETWORK_PROTOCOLR = "UPDATE_NETWORK_PROTOCOLR";
export const UPDATE_NETWORK_PROTOCOLR_SUCCESS =
  "UPDATE_NETWORK_PROTOCOLR_SUCCESS";
export const UPDATE_NETWORK_PROTOCOLR_FAILURE =
  "UPDATE_NETWORK_PROTOCOLR_FAILURE";
export const END_UPDATE_NETWORK_PROTOCOLR = "END_UPDATE_NETWORK_PROTOCOLR";

const fetchNetworkProtocols = () => {
  return { type: FETCH_NETWORK_PROTOCOLRS, error: null };
};

const fetchNetworkProtocolsSucces = json => {
  return {
    type: FETCH_NETWORK_PROTOCOLRS_SUCCESS,
    networkProtocols: json,
    error: null
  };
};

const fetchNetworkProtocolsFailure = error => {
  return { type: FETCH_NETWORK_PROTOCOLRS_FAILURE, error: error };
};

const createNetworkProtocolRequest = () => {
  return { type: CREATE_NETWORK_PROTOCOLR, error: null };
};

const createNetworkProtocolSucces = json => {
  return {
    type: CREATE_NETWORK_PROTOCOLR_SUCCESS,
    networkProtocol: json,
    error: null
  };
};

const createNetworkProtocolFailure = error => {
  return { type: CREATE_NETWORK_PROTOCOLR_FAILURE, error: error };
};

const updateNetworkProtocolRequest = () => {
  return { type: UPDATE_NETWORK_PROTOCOLR, error: null };
};

const updateNetworkProtocolSucces = json => {
  return {
    type: UPDATE_NETWORK_PROTOCOLR_SUCCESS,
    networkProtocol: json,
    error: null
  };
};

const updateNetworkProtocolFailure = error => {
  return { type: UPDATE_NETWORK_PROTOCOLR_FAILURE, error: error };
};

export const startUpdateNetworkProtocol = updateItem => {
  return { type: START_UPDATE_NETWORK_PROTOCOLR, updateItem: updateItem };
};

export const startCreateNetworkProtocol = () => {
  return { type: START_CREATE_NETWORK_PROTOCOLR };
};

export const endUpdateNetworkProtocol = () => {
  return { type: END_UPDATE_NETWORK_PROTOCOLR };
};

export const endCreateNetworkProtocol = () => {
  return { type: END_CREATE_NETWORK_PROTOCOLR };
};

export const fetchNetworkProtocolList = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchNetworkProtocols());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/network_protocols`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchNetworkProtocolsSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchNetworkProtocolsFailure(error));
        }
      });
  };
};

export const createNetworkProtocol = params => {
  return (dispatch, getState) => {
    dispatch(createNetworkProtocolRequest());
    const user = getState().user.user;

    return axios
      .post(`${endPoint}/api/v1/network_protocols`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(createNetworkProtocolSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createNetworkProtocolFailure(error));
        }
      });
  };
};

export const updateNetworkProtocol = (network_protocol, params) => {
  return (dispatch, getState) => {
    dispatch(updateNetworkProtocolRequest());
    const user = getState().user.user;

    return axios
      .put(
        `${endPoint}/api/v1/network_protocols/${network_protocol.id}`,
        params,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
      .then(response => {
        dispatch(updateNetworkProtocolSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(updateNetworkProtocolFailure(error));
        }
      });
  };
};
