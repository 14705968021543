import React, { Component } from "react";
import { connect } from "react-redux";
import { saveToken, getRedirectUrl } from "../../store/actions/";
import DataTable from "react-data-table-component";

import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  Col,
  Row,
  Table,
  Button
} from "reactstrap";
import { Redirect } from "react-router-dom";

class Token extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.saveToken(this.props.match.params.id);
    this.props.getRedirectUrl();
  }

  render() {
    const { isSaving, redirectUrl, user, isLoading } = this.props;

    if ((isSaving && user.user == null) || isLoading) {
      return <div> Loading ... </div>;
    }

    return <Redirect to={redirectUrl}></Redirect>;
  }
}

// here, we bind the redux state as component props:
const mapStateToProps = state => {
  return {
    isSaving: state.user.isSaving,
    isLoading: state.user.isLoading,
    user: state.user,
    redirectUrl: state.user.redirectUrl
  };
};
// and bind the action dispatch to a prop as well:
const mapDispatchToProps = dispatch => ({
  saveToken: params => dispatch(saveToken(params)),
  getRedirectUrl: () => dispatch(getRedirectUrl())
});

// and connect it to redux :)
export default connect(mapStateToProps, mapDispatchToProps)(Token);
