import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import {
  fetchDeviceTypeSucces,
  fetchDeviceTypeFailure,
  fetchDeviceType
} from "./deviceTypes.js";
import { setJsonChart } from "../../redux/actions";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_ALTIOR_APPLICATIONS = "FETCH_ALTIOR_APPLICATIONS";
export const FETCH_ALTIOR_APPLICATIONS_SUCCESS =
  "FETCH_ALTIOR_APPLICATIONS_SUCCESS";
export const FETCH_ALTIOR_APPLICATIONS_FAILURE =
  "FETCH_ALTIOR_APPLICATIONS_FAILURE";
export const SHOW_ALTIOR_APPLICATIONS = "SHOW_ALTIOR_APPLICATIONS";
export const SHOW_ALTIOR_APPLICATIONS_SUCCESS =
  "SHOW_ALTIOR_APPLICATIONS_SUCCESS";
export const SHOW_ALTIOR_APPLICATIONS_FAILURE =
  "SHOW_ALTIOR_APPLICATIONS_FAILURE";
export const START_CREATE_ALTIOR_APPLICATION =
  "START_CREATE_ALTIOR_APPLICATION";
export const CREATE_ALTIOR_APPLICATION = "CREATE_ALTIOR_APPLICATION";
export const CREATE_ALTIOR_APPLICATION_SUCCESS =
  "CREATE_ALTIOR_APPLICATION_SUCCESS";
export const CREATE_ALTIOR_APPLICATION_FAILURE =
  "CREATE_ALTIOR_APPLICATION_FAILURE";
export const END_CREATE_ALTIOR_APPLICATION = "END_CREATE_ALTIOR_APPLICATION";
export const START_UPDATE_ALTIOR_APPLICATION =
  "START_UPDATE_ALTIOR_APPLICATION";
export const UPDATE_ALTIOR_APPLICATION = "UPDATE_ALTIOR_APPLICATION";
export const UPDATE_ALTIOR_APPLICATION_SUCCESS =
  "UPDATE_ALTIOR_APPLICATION_SUCCESS";
export const UPDATE_ALTIOR_APPLICATION_FAILURE =
  "UPDATE_ALTIOR_APPLICATION_FAILURE";
export const END_UPDATE_ALTIOR_APPLICATION = "END_UPDATE_ALTIOR_APPLICATION";

export const START_PREPARE_FLOW_CHART = "START_PREPARE_FLOW_CHART";
export const START_PREPARE_FLOW_CHART_SUCCESS =
  "START_PREPARE_FLOW_CHART_SUCCESS";
export const START_PREPARE_FLOW_CHART_ERROR = "START_PREPARE_FLOW_CHART_ERROR";

export const PUBLISH_ALTIOR_APPLICATION = "PUBLISH_ALTIOR_APPLICATION";
export const PUBLISH_ALTIOR_APPLICATION_SUCCESS =
  "PUBLISH_ALTIOR_APPLICATION_SUCCESS";
export const PUBLISH_ALTIOR_APPLICATION_FAILURE =
  "PUBLISH_ALTIOR_APPLICATION_FAILURE";
export const STOP_ALTIOR_APPLICATION = "STOP_ALTIOR_APPLICATION";
export const STOP_ALTIOR_APPLICATION_SUCCESS =
  "STOP_ALTIOR_APPLICATION_SUCCESS";
export const STOP_ALTIOR_APPLICATION_FAILURE =
  "STOP_ALTIOR_APPLICATION_FAILURE";

const fetchAltiorApplications = () => {
  return { type: FETCH_ALTIOR_APPLICATIONS, error: null };
};

const fetchAltiorApplicationsSucces = json => {
  return {
    type: FETCH_ALTIOR_APPLICATIONS_SUCCESS,
    altiorApplications: json,
    error: null
  };
};

const fetchAltiorApplicationsFailure = error => {
  return { type: SHOW_ALTIOR_APPLICATIONS_FAILURE, error: error };
};

const showAltiorApplication = () => {
  return { type: SHOW_ALTIOR_APPLICATIONS, error: null };
};

const showAltiorApplicationSuccess = json => {
  return {
    type: SHOW_ALTIOR_APPLICATIONS_SUCCESS,
    altiorApplication: json,
    error: null
  };
};

const showAltiorApplicationFailure = error => {
  return { type: SHOW_ALTIOR_APPLICATIONS_FAILURE, error: error };
};
const createAltiorApplicationRequest = () => {
  return { type: CREATE_ALTIOR_APPLICATION, error: null };
};

const createAltiorApplicationSucces = json => {
  return {
    type: CREATE_ALTIOR_APPLICATION_SUCCESS,
    altiorApplication: json,
    error: null
  };
};

const createAltiorApplicationFailure = error => {
  return { type: CREATE_ALTIOR_APPLICATION_FAILURE, error: error };
};

const updateAltiorApplicationRequest = () => {
  return { type: UPDATE_ALTIOR_APPLICATION, error: null };
};

const updateAltiorApplicationSucces = json => {
  return {
    type: UPDATE_ALTIOR_APPLICATION_SUCCESS,
    altiorApplication: json,
    error: null
  };
};

const startPrepareFlowChart = () => {
  return { type: START_PREPARE_FLOW_CHART };
};

const startPrepareFlowChartSucess = () => {
  return { type: START_PREPARE_FLOW_CHART_SUCCESS };
};

const startPrepareFlowChartError = error => {
  return { type: START_PREPARE_FLOW_CHART_ERROR, error: error };
};

const updateAltiorApplicationFailure = error => {
  return { type: UPDATE_ALTIOR_APPLICATION_FAILURE, error: error };
};

export const startUpdateAltiorApplication = updateItem => {
  return { type: START_UPDATE_ALTIOR_APPLICATION, updateItem: updateItem };
};

export const startCreateAltiorApplication = () => {
  return { type: START_CREATE_ALTIOR_APPLICATION };
};

export const endUpdateAltiorApplication = () => {
  return { type: END_UPDATE_ALTIOR_APPLICATION };
};

export const endCreateAltiorApplication = () => {
  return { type: END_CREATE_ALTIOR_APPLICATION };
};

const publishAltiorApplicationRequest = () => {
  return { type: PUBLISH_ALTIOR_APPLICATION, error: null };
};

const publishAltiorApplicationSucces = json => {
  return {
    type: PUBLISH_ALTIOR_APPLICATION_SUCCESS,
    error: null
  };
};

const publishAltiorApplicationFailure = error => {
  return { type: PUBLISH_ALTIOR_APPLICATION_FAILURE, error: error };
};

const stopAltiorApplicationRequest = () => {
  return { type: STOP_ALTIOR_APPLICATION, error: null };
};

const stopAltiorApplicationSucces = json => {
  return {
    type: STOP_ALTIOR_APPLICATION_SUCCESS,
    error: null
  };
};

const stopAltiorApplicationFailure = error => {
  return { type: STOP_ALTIOR_APPLICATION_FAILURE, error: error };
};

export const fetchAltiorApplicationList = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchAltiorApplications());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/altior_applications`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchAltiorApplicationsSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchAltiorApplicationsFailure(error));
        }
      });
  };
};

export const createAltiorApplication = params => {
  return (dispatch, getState) => {
    dispatch(createAltiorApplicationRequest());
    const user = getState().user.user;

    return axios
      .post(`${endPoint}/api/v1/altior_applications`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(createAltiorApplicationSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createAltiorApplicationFailure(error));
        }
      });
  };
};

export const updateAltiorApplication = (id, params) => {
  return (dispatch, getState) => {
    dispatch(updateAltiorApplicationRequest());
    const user = getState().user.user;

    return axios
      .put(`${endPoint}/api/v1/altior_applications/${id}`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(updateAltiorApplicationSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(updateAltiorApplicationFailure(error));
        }
      });
  };
};
export const showAltiorApplicationRequest = id => {
  return (dispatch, getState) => {
    dispatch(showAltiorApplication());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/altior_applications/${id}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(showAltiorApplicationSuccess(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(showAltiorApplicationFailure(error));
        }
      });
  };
};

export const fetchFlowChartAndDeviceType = id => {
  return (dispatch, getState) => {
    dispatch(startPrepareFlowChart());
    dispatch(showAltiorApplication());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/altior_applications/${id}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        if (response.data.data.json) {
          dispatch(setJsonChart(response.data.data.json));
          dispatch(startPrepareFlowChartSucess());
        } else {
          dispatch(showAltiorApplicationSuccess(response.data));
          if (
            !getState().deviceTypes.deviceType ||
            getState().deviceTypes.id != response.data.data.device_type_id
          ) {
            dispatch(fetchDeviceType(response.data.data.device_type_id));
            return axios
              .get(
                `${endPoint}/api/v1/device_types/${response.data.data.device_type_id}`,
                {
                  headers: { Authorization: `Bearer ${user.accessToken}` }
                }
              )
              .then(deviceTypeData => {
                dispatch(fetchDeviceTypeSucces(deviceTypeData.data));
                const metadataFromAPI = {
                  ...response.data.data,
                  device_type: { ...deviceTypeData.data.data }
                };
                dispatch(
                  setJsonChart({
                    offset: {
                      x: 0,
                      y: 0
                    },
                    nodes: {},
                    links: {},
                    selected: {},
                    hovered: {},
                    records: {},
                    metadata: metadataFromAPI,
                    envvars: []
                  })
                );
                dispatch(startPrepareFlowChartSucess());
              })
              .catch(error => {
                dispatch(fetchDeviceTypeFailure(error));
                dispatch(startPrepareFlowChartError(error));
              });
          }
        }
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(showAltiorApplicationFailure(error));
          dispatch(startPrepareFlowChartError(error));
        }
      });
  };
};

export const publishAltiorApplication = (id, configuration) => {
  return (dispatch, getState) => {
    dispatch(publishAltiorApplicationRequest());
    const user = getState().user.user;

    return axios
      .post(
        `${endPoint}/api/v1/altior_applications/${id}/publish`,
        configuration,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        }
      )
      .then(response => {
        dispatch(publishAltiorApplicationSucces(response.data));
        dispatch(fetchAltiorApplicationList());
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(publishAltiorApplicationFailure(error));
        }
      });
  };
};
export const stopAltiorApplication = id => {
  return (dispatch, getState) => {
    dispatch(stopAltiorApplicationRequest());
    const user = getState().user.user;

    return axios
      .delete(`${endPoint}/api/v1/altior_applications/${id}/publish`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(stopAltiorApplicationSucces(response.data));
        dispatch(fetchAltiorApplicationList());
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(stopAltiorApplicationFailure(error));
        }
      });
  };
};
