import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_PROTOCOL_ATTRIBUTE = "FETCH_PROTOCOL_ATTRIBUTE";
const fetchProtocolAttributes = protocolId => {
  return { type: FETCH_PROTOCOL_ATTRIBUTE, protocolId: protocolId };
};

export const FETCH_PROTOCOL_ATTRIBUTE_SUCCESS =
  "FETCH_PROTOCOL_ATTRIBUTE_SUCCESS";
const fetchProtocolAttributesSuccess = (json, protocolId) => {
  return {
    type: FETCH_PROTOCOL_ATTRIBUTE_SUCCESS,
    attributes: json,
    protocolId: protocolId,
    error: null
  };
};

export const FETCH_PROTOCOL_ATTRIBUTE_FAILURE =
  "FETCH_PROTOCOL_ATTRIBUTE_FAILURE";
const fetchProtocolAttributesFailure = (error, protocolId) => {
  return {
    type: FETCH_PROTOCOL_ATTRIBUTE_FAILURE,
    error: error,
    protocolId: protocolId
  };
};

export const fetchProtocolAttributesList = protocolId => {
  return (dispatch, getState) => {
    dispatch(fetchProtocolAttributes(protocolId));
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/protocols/${protocolId}/attributes`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchProtocolAttributesSuccess(response.data, protocolId));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchProtocolAttributesFailure(error, protocolId));
        }
      });
  };
};
