import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_ALTIOR_STAT_BASE_URL;

export const FETCH_STATISTIC = "FETCH_STATISTIC";
const fetchStatistic = () => {
  return { type: FETCH_STATISTIC, error: null };
};

export const FETCH_STATISTIC_SUCCESS = "FETCH_STATISTIC_SUCCESS";
const fetchStatisticsSucces = json => {
  return {
    type: "PHOENIX_SOCKET_MESSAGE_FROM_CHANNEL",
    channel: `${json.data.stat_type}:${json.data.node}:${json.data.sub_stat_type}`,
    messageType: "updated",
    payload: json.data
  };
};

export const FETCH_STATISTIC_FAILURE = "FETCH_STATISTIC_FAILURE";
const fetchStatisticsFailure = error => {
  return { type: FETCH_STATISTIC_FAILURE, error: error };
};

export const fetchStatisticItem = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchStatistic());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/stat_message/latest`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchStatisticsSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchStatisticsFailure(error));
        }
      });
  };
};
