import chart from "./chart";
import apis from "./apis";
import protocols from "../../store/reducers/protocols";
import securityProvider from "../../store/reducers/securityProviders";
import networkProtocol from "../../store/reducers/networkProtocols";
import peripheralConfigurations from "../../store/reducers/peripheralConfigurations";
import deviceTypes from "../../store/reducers/deviceTypes";
import image from "../../store/reducers/image";
import baseFields from "../../store/reducers/baseFields";
import protocolAttributes from "../../store/reducers/protocolAttributes";
import protocolActions from "../../store/reducers/protocolActions";
import valueTypes from "../../store/reducers/valueTypes";
import user from "../../store/reducers/user";
import deviceTypeObject from "../../store/reducers/deviceTypeObject";
import userGroups from "../../store/reducers/userGroups";
import builder from "../../store/reducers/builder";
import modal from "../../store/reducers/modal";
import navigation from "../../store/reducers/navigation";
import robo_swarm from "./robo_swarm";
import webSocket from "./web_socket";
import phoenixSocket from "./phoenixSocket";
import consoleLog from "./consoleLog";
import applicationDebugger from "./applicationDebugger";
import altiorApplication from "../../store/reducers/altiorApplication";
import rightSideMenu from "./rightSideMenu";
import { combineReducers } from "redux";

export default combineReducers({
  chart,
  apis,
  protocols,
  user,
  userGroups,
  modal,
  navigation,
  securityProvider,
  networkProtocol,
  peripheralConfigurations,
  deviceTypes,
  baseFields,
  protocolAttributes,
  valueTypes,
  builder,
  robo_swarm,
  webSocket,
  phoenixSocket,
  consoleLog,
  applicationDebugger,
  rightSideMenu,
  deviceTypeObject,
  image,
  altiorApplication,
  protocolActions
});
