import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import units from "./lang/en/units";
import protocols from "./lang/en/protocols";
import network_protocols from "./lang/en/network_protocols";
import security_provider from "./lang/en/security_provider";
import paginator from "./lang/en/paginator";
import deviceType from "./lang/en/deviceType";
import defaultHeader from "./lang/en/defaultHeader";
import baseFields from "./lang/en/baseFields";
import process from "./lang/en/process";
import objects from "./lang/en/objects";
import compiler from "./lang/en/compiler";
import dashboard from "./lang/en/dashboard";
import peripheral_configuration from "./lang/en/peripheral_configuration";
import flowcharts from "./lang/en/flowcharts";
import altior_application_publishers from "./lang/en/altior_application_publishers";
import nameVersionFilter from "./lang/en/nameVersionFilter";
import userGroups from "./lang/en/userGroups";
import inputGroups from "./lang/en/inputGroups";
import inputFile from "./lang/en/inputFile";
import general from "./lang/en/general";
import roboSwarm from "./lang/en/roboSwarm";
import vmStats from "./lang/en/vmStats";
import record from "./lang/en/record";
import attributes from "./lang/en/attributes";
import rightSideMenu from "./lang/en/rightSideMenu";
import envVar from "./lang/en/envVar";
import commandsBar from "./lang/en/commandsBar";
import info from "./lang/en/info";
import altiorConfirm from "./lang/en/altiorConfirm";

import unitsIT from "./lang/it/units";
import protocolsIT from "./lang/it/protocols";
import security_providerIT from "./lang/it/security_provider";
import paginatorIT from "./lang/it/paginator";
import deviceTypeIT from "./lang/it/deviceType";
import defaultHeaderIT from "./lang/it/defaultHeader";
import processIT from "./lang/it/process";
import moment from "moment";

// the translations
// (tip move them in a JSON file and import them)

i18n
  .use(XHR)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    languages: ["en", "it"],
    lng: "en",

    fallbackLng: "en", // use en if detected lng is not available
    //fallbackLng: ['en','it'], // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (format === "uppercase") return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      }
    },

    resources: {
      en: {
        units: units,
        protocols: protocols,
        security_provider: security_provider,
        paginator: paginator,
        network_protocols: network_protocols,
        deviceType: deviceType,
        defaultHeader: defaultHeader,
        baseFields: baseFields,
        objects: objects,
        process: process,
        compiler: compiler,
        dashboard: dashboard,
        peripheral_configuration: peripheral_configuration,
        flowcharts: flowcharts,
        altior_application_publishers: altior_application_publishers,
        nameVersionFilter: nameVersionFilter,
        userGroups: userGroups,
        inputGroups: inputGroups,
        inputFile: inputFile,
        general: general,
        robo_swarm: roboSwarm,
        vmStats,
        record,
        attributes,
        rightSideMenu,
        envVar,
        commandsBar,
        info,
        altiorConfirm
      },
      it: {
        units: unitsIT,
        protocols: protocolsIT,
        security_provider: security_providerIT,
        paginator: paginatorIT,
        deviceType: deviceTypeIT,
        defaultHeader: defaultHeaderIT,
        process: processIT
      }
    },
    // have a common namespace used around the full app
    ns: [
      "units",
      "protocols",
      "network_protocols",
      "security_provider",
      "paginator",
      "deviceType",
      "defaultHeader",
      "baseFields",
      "objects"
    ],
    defaultNS: "units",
    backend: {
      loadPath: "./lang/{{lng}}/{{ns}}.json"
    }
  });

// catch the event and make changes accordingly
i18n.on("languageChanged", function(lng) {});

export default i18n;
