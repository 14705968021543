import {
    FETCH_BASE_FIELDS,
    FETCH_BASE_FIELDS_FAILURE,
    FETCH_BASE_FIELDS_SUCCESS,
   
} from '../actions/'

const BaseFieldReducer = (state = { isLoading: false, items: [], error: null }, action) => {
    switch (action.type) {
        case FETCH_BASE_FIELDS:
            return { ...state, isLoading: true, error: null, items: [] };
        case FETCH_BASE_FIELDS_SUCCESS:
            return { ...state, isLoading: false, items: action.BaseFields.data };
        case FETCH_BASE_FIELDS_FAILURE:
            return { ...state, isLoading: false, error: action.error, items: [] };
       
        default:
            return state;
    }
}

export default BaseFieldReducer