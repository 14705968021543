import URL from './config'

const showImage = (image_id = 1) => {
console.log(URL.BASE_URL)
    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/images/${image_id}`)
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{
            
              resolve(result);
          }
        })
      });
}

const createImage = (image_file) => {
    
    const files = Array.from(image_file)

    const data = new FormData()
    data.append("image",files[0])

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/images`, {
            method: 'POST',
            body: data
        })
        .then(res => {
            if(res.status > 300){
                return {
                    error: {
                        statusCode: res.status,
                        reason: res.statusText
                    }
                }
            }else{
                return res.json()
            }
        })
        .then(result => {
            if(result.error) {
                console.log(result)
                reject(result.error);
            }else{
                resolve(result);
            }
        })
        .catch((error) =>{
            reject(`${error}`);
          })

    })
}

export default { showImage: showImage, createImage: createImage}
