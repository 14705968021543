import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_IMAGE = "FETCH_IMAGE";
export const CLEAR_IMAGE = "CLEAR_IMAGE";
export const FETCH_IMAGE_SUCCESS = "FETCH_IMAGE_SUCCESS";
export const FETCH_IMAGE_FAILURE = "FETCH_IMAGE_FAILURE";

const fetchImage = id => {
  return { type: FETCH_IMAGE, error: null, id: id };
};

const fetchImageSucces = json => {
  return { type: FETCH_IMAGE_SUCCESS, image: json, error: null };
};

const fetchImageFailure = error => {
  return { type: FETCH_IMAGE_FAILURE, error: error };
};
export const showImage = (deviceTypeId, id) => {
  return (dispatch, getState) => {
    dispatch(fetchImage(id));
    const user = getState().user.user;

    return axios
      .get(`${endPoint}/api/v1/device_types/${deviceTypeId}/images/${id}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchImageSucces(response.data.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchImageFailure(error));
        }
      });
  };
};

export const clearImage = () => {
  return { type: CLEAR_IMAGE };
};
