import axios from "axios";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_BUILDER_REQUEST = "FETCH_BUILDER_REQUEST";
export const FETCH_BUILDER_SUCCES = "FETCH_BUILDER_SUCCES";
export const FETCH_BUILDER_ERROR = "FETCH_BUILDER_ERROR";

export const CREATE_BUILD_REQUEST = "CRAEATE_BUILD_REQUEST";
export const CREATE_BUILD_SUCCES = "CRAEAT_BUILD_SUCCES";
export const CREATE_BUILD_ERROR = "CREATE_BUILD_ERROR";

// Compilation = Compilation + instance zero!
export const BUILD_START = "BUILD_START";
export const BUILD_MESSAGE = "BUILD_MESSAGE";
export const BUILD_END_SUCCESS = "BUILD_END_SUCCESS";
export const BUILD_END_ERROR = "BUILD_END_ERROR";
export const CLEAR_BUILD_MESSAGES = "CLEAR_BUILD_MESSAGES";

export const clearBuildMessages = () => {
  return { type: CLEAR_BUILD_MESSAGES };
};

const fetchBuilderRequest = () => {
  return { type: FETCH_BUILDER_REQUEST };
};

const fetchBuilderSucess = json => {
  return {
    type: FETCH_BUILDER_SUCCES,
    builds: json.data,
    meta: json.meta,
    error: null
  };
};

const fetchBuilderError = error => {
  return { type: FETCH_BUILDER_ERROR, error: error };
};

const createBuildRequest = () => {
  return { type: CREATE_BUILD_REQUEST };
};

const createBuildSucess = json => {
  return { type: CREATE_BUILD_SUCCES, build: json.data, error: null };
};

const createBuildError = error => {
  return { type: CREATE_BUILD_ERROR, error: error };
};

export const buildStart = () => {
  return { type: BUILD_START };
};

export const buildMessage = (channel, messageType, payload) => {
  return {
    type: BUILD_MESSAGE,
    channel: channel,
    messageType: messageType,
    payload: payload
  };
};

export const buildEndSuccess = () => {
  return { type: BUILD_END_SUCCESS };
};

export const buildEndError = error => {
  return { type: BUILD_END_ERROR, error: error };
};

export const fetchBuilder = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchBuilderRequest());

    const user = getState().user.user;
    const deviceTypeId = params.deviceTypeId;
    if (deviceTypeId && user) {
      return axios
        .get(
          `${endPoint}/api/v1/device_types/${deviceTypeId}/compiling_results`,
          {
            params: params,
            headers: { Authorization: `Bearer ${user.accessToken}` }
          }
        )
        .then(response => {
          dispatch(fetchBuilderSucess(response.data));
        })
        .catch(error => {
          if (checkErrorResponse(dispatch, user, error)) {
            dispatch(fetchBuilderError(error));
          }
        });
    } else {
      dispatch(fetchBuilderError("Can't fetch builder"));
    }
  };
};

export const createBuild = (deviceTypeId, params) => {
  return (dispatch, getState) => {
    dispatch(createBuildRequest());
    dispatch(buildStart());
    const user = getState().user.user;
    return axios
      .post(
        `${endPoint}/api/v1/device_types/${deviceTypeId}/compiler`,
        params,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        }
      )
      .then(response => {
        dispatch(createBuildSucess(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createBuildError(error));
        }
      });
  };
};
