import {
    START_PREAPRE_NAVBAR_LIST,
    PREAPRE_NAVBAR_LIST_SUCCESS
} from '../actions/'

const navigation = (state = { navBarList: {}, isLoading: false, newNavBar: null }, action) => {
    switch (action.type) {
        case START_PREAPRE_NAVBAR_LIST:
            return { ...state, isLoading: true, user: action.user }
        case PREAPRE_NAVBAR_LIST_SUCCESS:
            return { ...state, isLoading: false, navBarList: {...state.navBarList, [action.userId]: action.newNavBar }, newNavBar: action.newNavBar }
        default:
            return state
    }
}


export default navigation