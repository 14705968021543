import URL from './config'

const fetchProtocolsPage = (page = 1,name = '') => {
    
    return new Promise((resolve, reject) => {
      fetch(`${URL.BASE_URL}/api/v1/protocols?page=${page}&name=${name}`)
      .then(res => {
        if(res.status > 300){
            return {
                error: {
                    statusCode: res.status,
                    reason: res.statusText
                }
            }
        }else{
            return res.json()
        }
      })
      .then(result => {
        if(result.error) {
            console.log(result.errors)
            reject(result.error);
        }else{
            resolve( result.data);
        }           
      })
    });
}

const createProtocol = (protocol) => { 

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/protocols`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
             "protocol": protocol
            })
        })
        .then(res => {
            if(res.status > 300){
                return {
                    error: {
                        statusCode: res.status,
                        reason: res.statusText
                    }
                }
            }else{
                return res.json()
            }
        })
        .then(result => {
            if(result.error) {
                console.log(result.errors)
                reject(result.error);
            }else{
                resolve(result.data);
            }
        })
        .catch((error) =>{
            reject(`${error}`);
          })
        
    })
}

export default {fetchProtocolsPage: fetchProtocolsPage, createProtocol: createProtocol}