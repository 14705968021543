import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const SHOW_DEVICE_TYPE_OBJECT = "SHOW_DEVICE_TYPE_OBJECT";
export const SHOW_DEVICE_TYPE_OBJECT_FAILURE =
  "SHOW_DEVICE_TYPE_OBJECT_FAILURE";
export const SHOW_DEVICE_TYPE_OBJECT_SUCCESS =
  "SHOW_DEVICE_TYPE_OBJECT_SUCCESS";
export const CREATE_DEVICE_TYPE_OBJECT = "CREATE_DEVICE_TYPE_OBJECT";
export const CREATE_DEVICE_TYPE_OBJECT_REQUEST_SENT =
  "CREATE_DEVICE_TYPE_OBJECT_REQUEST_SENT";
export const CREATE_DEVICE_TYPE_OBJECT_SUCCESS =
  "CREATE_DEVICE_TYPE_OBJECT_SUCCESS";
export const CREATE_DEVICE_TYPE_OBJECT_FAILURE =
  "CREATE_DEVICE_TYPE_OBJECT_FAILURE";
export const CREATE_DEVICE_TYPE_OBJECT_TOOGLE =
  "CREATE_DEVICE_TYPE_OBJECT_TOOGLE";
export const SEND_COMMAND_TO_DEVICE_TYPE_OBJECT =
  "SEND_COMMAND_TO_DEVICE_TYPE_OBJECT";
export const SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_FAILURE =
  "SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_FAILURE";
export const SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_SUCCESS =
  "SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_SUCCESS";
export const DELETE_DEVICE_TYPE_OBJECT = "DELETE_DEVICE_TYPE_OBJECT";
export const DELETE_DEVICE_TYPE_OBJECT_FAILURE =
  "DELETE_DEVICE_TYPE_OBJECT_FAILURE";
export const DELETE_DEVICE_TYPE_OBJECT_SUCCESS =
  "DELETE_DEVICE_TYPE_OBJECT_SUCCESS";
export const CLEAR_DEVICE_TYPE_OBJECT = "CLEAR_DEVICE_TYPE_OBJECT";

const sendCommandToDeviceTypeObject = (
  deviceTypeId,
  id,
  commandName,
  commandValue
) => {
  return {
    type: SEND_COMMAND_TO_DEVICE_TYPE_OBJECT,
    deviceTypeId: deviceTypeId,
    id: id,
    commandName: commandName,
    commandValue: commandValue
  };
};

const sendCommandToDeviceTypeObjectSuccess = () => {
  return {
    type: SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_SUCCESS
  };
};

const sendCommandToDeviceTypeObjectFailuer = error => {
  return {
    type: SEND_COMMAND_TO_DEVICE_TYPE_OBJECT_FAILURE,
    error: error
  };
};

const showDeviceTypeAction = (deviceTypeId, id) => {
  return {
    type: SHOW_DEVICE_TYPE_OBJECT,
    error: null,
    deviceTypeId: deviceTypeId,
    id: id
  };
};

const showDeviceTypeSuccess = json => {
  return {
    type: SHOW_DEVICE_TYPE_OBJECT_SUCCESS,
    deviceTypeObject: json,
    error: null
  };
};

const showDeviceTypeFailure = error => {
  return { type: SHOW_DEVICE_TYPE_OBJECT_FAILURE, error: error };
};

const createDeviceTypeObjectRequest = () => {
  return { type: CREATE_DEVICE_TYPE_OBJECT, error: null };
};

const createDeviceTypeObjectRequestSent = () => {
  return { type: CREATE_DEVICE_TYPE_OBJECT_REQUEST_SENT };
};

const createDeviceTypeObjectSucces = json => {
  return {
    type: CREATE_DEVICE_TYPE_OBJECT_SUCCESS,
    deviceTypeObject: json.message.message.identifier,
    error: null
  };
};

const createDeviceTypeObjectFailure = error => {
  return { type: CREATE_DEVICE_TYPE_OBJECT_FAILURE, error: error };
};

export const createDeviceTypeObjectToggle = currentValue => {
  return {
    type: CREATE_DEVICE_TYPE_OBJECT_TOOGLE,
    stateCreateDeviceTypeObjectModal: !currentValue
  };
};
const deleteDeviceTypeObjectAction = (deviceTypeId, id) => {
  return {
    type: DELETE_DEVICE_TYPE_OBJECT,
    error: null,
    deviceTypeId: deviceTypeId,
    id: id
  };
};

const deleteDeviceTypeObjectSuccess = json => {
  return {
    type: DELETE_DEVICE_TYPE_OBJECT_SUCCESS,
    error: null
  };
};

const deleteDeviceTypeObjectFailure = error => {
  return { type: DELETE_DEVICE_TYPE_OBJECT_FAILURE, error: error };
};

export const clearDeviceTypeObject = () => {
  return { type: CLEAR_DEVICE_TYPE_OBJECT };
};

export const deleteDeviceTypeObject = (deviceTypeId, id) => {
  return (dispatch, getState) => {
    dispatch(deleteDeviceTypeObjectAction(deviceTypeId, id));
    const user = getState().user.user;

    return axios
      .delete(`${endPoint}/api/v1/device_types/${deviceTypeId}/objects/${id}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(deleteDeviceTypeObjectSuccess());
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(deleteDeviceTypeObjectFailure(error));
        }
      });
  };
};
export const showDeviceTypeObject = (deviceTypeId, id) => {
  return (dispatch, getState) => {
    dispatch(showDeviceTypeAction());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/device_types/${deviceTypeId}/objects/${id}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(showDeviceTypeSuccess(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(showDeviceTypeFailure(error));
        }
      });
  };
};

export const createDeviceTypeObject = (deviceTypeId, params) => {
  return (dispatch, getState) => {
    dispatch(createDeviceTypeObjectRequest());
    const user = getState().user.user;

    return axios
      .post(
        `${endPoint}/api/v1/device_types/${deviceTypeId}/objects/`,
        params,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        }
      )
      .then(response => {
        dispatch(createDeviceTypeObjectRequestSent());
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createDeviceTypeObjectFailure(error));
        }
      });
  };
};

export const sendCommandToDeviceTypeObjectRequest = (
  deviceTypeId,
  id,
  params
) => {
  return (dispatch, getState) => {
    dispatch(
      sendCommandToDeviceTypeObject(
        deviceTypeId,
        id,
        params.commandName,
        params.commandValue
      )
    );
    const user = getState().user.user;

    return axios
      .post(
        `${endPoint}/api/v1/device_types/${deviceTypeId}/objects/${id}/states`,
        params,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        }
      )
      .then(response => {
        dispatch(sendCommandToDeviceTypeObjectSuccess());
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(sendCommandToDeviceTypeObjectFailuer(error));
        }
      });
  };
};

export const toggleCreateDeviceTypeObjectModal = () => {
  return (dispatch, getState) => {
    const currentState = getState().deviceTypeObject
      .stateCreateDeviceTypeObjectModal;
    dispatch(createDeviceTypeObjectToggle(!currentState));
  };
};
