import URL from './config'

const createState = (device_types_id,object_id,state) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types/${device_types_id}/objects/${object_id}/states`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
             "state": state
            })
        })
        .then(res => {
            if(res.status > 300){
                reject( {
                    error: {
                        statusCode: res.status,
                        reason: res.statusText
                    }
                }
            )}else{
                resolve()
            }
        })
        .catch((error) =>{
            reject(`${error}`);
          })

    })
}

export default {createState: createState}
