import {
    FETCH_PROTOCOLS,
    FETCH_PROTOCOLS_FAILURE,
    FETCH_PROTOCOLS_SUCCESS,
    CREATE_PROTOCOL,
    CREATE_PROTOCOL_SUCCESS,
    CREATE_PROTOCOL_FAILURE,
    UPDATE_PROTOCOL,
    UPDATE_PROTOCOL_SUCCESS,
    UPDATE_PROTOCOL_FAILURE,
    START_CREATE_PROTOCOL,
    START_UPDATE_PROTOCOL,
    END_UPDATE_PROTOCOL,
    END_CREATE_PROTOCOL
} from '../actions/'

const protocolReducer = (state = { isLoading: false, items: [], error: null, isCreating: false, isUpdating: false }, action) => {
    switch (action.type) {
        case FETCH_PROTOCOLS:
            return { ...state, isLoading: true, error: null, items: [] };
        case FETCH_PROTOCOLS_SUCCESS:
            return { ...state, isLoading: false, items: action.protocols.data, meta: action.protocols.meta };
        case FETCH_PROTOCOLS_FAILURE:
            return { ...state, isLoading: false, error: action.error, items: [] };
        case CREATE_PROTOCOL:
            return { ...state, isCreateLoading: true, error: null, item: null }
        case CREATE_PROTOCOL_SUCCESS:
            return { ...state, isCreateLoading: false, error: null, items: [...state.items, action.protocol.data], item: action.protocol.data, isCreating: false }
        case CREATE_PROTOCOL_FAILURE:
            return { ...state, isCreateLoading: false, error: action.error, item: null, isCreating: false }
        case UPDATE_PROTOCOL:
            return { ...state, isLoading: true, error: null, item: null  }
        case UPDATE_PROTOCOL_SUCCESS:
            return { ...state, isLoading: false, error: null, item: action.protocol.data, items: findAndReplace(state.items, action.protocol.data), isUpdating: false, updateItem: null }
        case UPDATE_PROTOCOL_FAILURE:
            return { ...state, isLoading: false, error: action.error, item: null, isUpdating: false  }
        case START_UPDATE_PROTOCOL:
            return { ...state, isUpdating: true , updateItem: action.updateItem }
        case START_CREATE_PROTOCOL: 
            return { ...state, isCreating: true, updateItem: null  }
        case END_UPDATE_PROTOCOL:
            return { ...state, isUpdating: false }
        case END_CREATE_PROTOCOL: 
            return { ...state, isCreating: false, updateItem: null  }
        default:
            return state;
    }
}

const findAndReplace = (items, newItem) => {
    const index = items.findIndex(i => i.id == newItem.id)
    if(index == -1){
        return [...items, newItem]
    }
    
    return items.map((element, i) => i == index ? newItem : element)
}
export default protocolReducer