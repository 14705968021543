import {
  APPLICATION_DEBUG_INIT_OK,
  APPLICATION_DEBUG_INIT,
  APPLICATION_DEBUG_START,
  APPLICATION_DEBUG_STARTED,
  APPLICATION_DEBUG_STOP
} from "../actionTypes";
const applicationDebuggerReducer = (
  state = {
    started: false,
    isStarting: false,
    isCompiling: false,
    compiled: false,
    isStopped: false
  },
  action
) => {
  switch (action.type) {
    case APPLICATION_DEBUG_INIT:
      return {
        ...state,
        started: false,
        isStarting: true,
        isCompiling: false,
        compiled: false
      };
    case APPLICATION_DEBUG_INIT_OK:
      return {
        ...state,
        started: true,
        isStarting: false
      };

    case APPLICATION_DEBUG_START:
      return { ...state, isCompiling: true, compiled: false };
    case APPLICATION_DEBUG_STARTED:
      return { ...state, isCompiling: false, compiled: true };
    case APPLICATION_DEBUG_STOP:
      return {
        ...state,
        isCompiling: false,
        compiled: false,
        isStarting: false,
        isCompiling: false,
        isStopped: true
      };
    default:
      return state;
  }
};
export default applicationDebuggerReducer;
