import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_PROTOCOLS = "FETCH_PROTOCOLS";
const fetchProtocols = () => {
  return { type: FETCH_PROTOCOLS, error: null };
};

export const FETCH_PROTOCOLS_SUCCESS = "FETCH_PROTOCOLS_SUCCESS";
const fetchProtocolsSucces = json => {
  return { type: FETCH_PROTOCOLS_SUCCESS, protocols: json, error: null };
};

export const FETCH_PROTOCOLS_FAILURE = "FETCH_PROTOCOLS_FAILURE";
const fetchProtocolsFailure = error => {
  return { type: FETCH_PROTOCOLS_FAILURE, error: error };
};

export const fetchProtocolList = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchProtocols());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/protocols`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchProtocolsSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchProtocolsFailure(error));
        }
      });
  };
};

export const CREATE_PROTOCOL = "CREATE_PROTOCOL";
const createProtocolRequest = () => {
  return { type: CREATE_PROTOCOL, error: null };
};

export const CREATE_PROTOCOL_SUCCESS = "CREATE_PROTOCOL_SUCCESS";
const createProtocolSucces = json => {
  return { type: CREATE_PROTOCOL_SUCCESS, protocol: json, error: null };
};

export const CREATE_PROTOCOL_FAILURE = "CREATE_PROTOCOL_FAILURE";
const createProtocolFailure = error => {
  return { type: CREATE_PROTOCOL_FAILURE, error: error };
};

export const createProtocol = params => {
  return (dispatch, getState) => {
    dispatch(createProtocolRequest());
    const user = getState().user.user;

    return axios
      .post(`${endPoint}/api/v1/protocols`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(createProtocolSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createProtocolFailure(error));
        }
      });
  };
};

export const UPDATE_PROTOCOL = "UPDATE_PROTOCOL";
const updateProtocolRequest = () => {
  return { type: UPDATE_PROTOCOL, error: null };
};

export const UPDATE_PROTOCOL_SUCCESS = "UPDATE_PROTOCOL_SUCCESS";
const updateProtocolSucces = json => {
  return { type: UPDATE_PROTOCOL_SUCCESS, protocol: json, error: null };
};

export const UPDATE_PROTOCOL_FAILURE = "UPDATE_PROTOCOL_FAILURE";
const updateProtocolFailure = error => {
  return { type: UPDATE_PROTOCOL_FAILURE, error: error };
};

export const updateProtocol = (protocol, params) => {
  return (dispatch, getState) => {
    dispatch(updateProtocolRequest());
    const user = getState().user.user;

    return axios
      .put(`${endPoint}/api/v1/protocols/${protocol.id}`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(updateProtocolSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(updateProtocolFailure(error));
        }
      });
  };
};

export const START_UPDATE_PROTOCOL = "START_UPDATE_PROTOCOL";
export const startUpdateProtocol = updateItem => {
  return { type: START_UPDATE_PROTOCOL, updateItem: updateItem };
};

export const START_CREATE_PROTOCOL = "START_CREATE_PROTOCOL";
export const startCreateProtocol = () => {
  return { type: START_CREATE_PROTOCOL };
};

export const END_UPDATE_PROTOCOL = "END_UPDATE_PROTOCOL";
export const endUpdateProtocol = () => {
  return { type: END_UPDATE_PROTOCOL };
};

export const END_CREATE_PROTOCOL = "END_CREATE_PROTOCOL";
export const endCreateProtocol = () => {
  return { type: END_CREATE_PROTOCOL };
};
