import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxWebsocket from '@giantmachines/redux-websocket';
import thunk from "redux-thunk";
import phoenixWebSocket from './middlewares/phoenixWebSocket'

import rootReducer from "./reducers";

const reduxWebsocketMiddleware = reduxWebsocket({ reconnectOnClose: true });

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, reduxWebsocketMiddleware, phoenixWebSocket))
);