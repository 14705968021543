// GUI
export const ON_DRAG_NODE = "ON_DRAG_NODE";
export const ON_DRAG_CANVAS = "ON_DRAG_CANVAS";
export const ON_LINK_START = "ON_LINK_START";
export const ON_LINK_MOVE = "ON_LINK_MOVE";
export const ON_LINK_COMPLETE = "ON_LINK_COMPLETE";
export const ON_LINK_CANCEL = "ON_LINK_CANCEL";
export const ON_LINK_MOUSE_ENTER = "ON_LINK_MOUSE_ENTER";
export const ON_LINK_MOUSE_LEAVE = "ON_LINK_MOUSE_LEAVE";
export const ON_LINK_CLICK = "ON_LINK_CLICK";
export const ON_CANVAS_CLICK = "ON_CANVAS_CLICK";
export const ON_DELETE_KEY = "ON_DELETE_KEY";
export const ON_NODE_CLICK = "ON_NODE_CLICK";
export const ON_NODE_SIZE_CHANGE = "ON_NODE_SIZE_CHANGE";
export const ON_PORT_POSITION_CHANGE = "ON_PORT_POSITION_CHANGE";
export const ON_CANVAS_DROP = "ON_CANVAS_DROP";

// Internal states
export const DUMP_STATE = "DUMP_STATE";
export const ON_INPUT_CHANGE = "ON_INPUT_CHANGE";
export const LOAD_JSON_CHART = "LOAD_JSON_CHART";
export const SET_JSON_CHART = "SET_JSON_CHART";
export const ADD_NEW_RECORD = "ADD_NEW_RECORD";
export const SET_ENVVARS = "SET_ENVVARS";
export const DEL_ENVVAR = "DEL_ENVVAR";
export const ADD_ENVVAR = "ADD_ENVVAR";
export const SET_METADATA = "SET_METADATA";
export const CHART_SAVE = "CHART_SAVE";

// API
export const SET_ISLOADING = "SET_ISLOADING";
export const SET_ISNOTLOADING = "SET_ISNOTLOADING";
export const SET_REPLY = "SET_REPLY";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const SET_ATTRIBUTES_VALUES = "SET_ATTRIBUTES_VALUES";
export const SET_ATTRIBUTE_VALUE = "SET_ATTRIBUTE_VALUE";
export const SET_EVENTS = "SET_EVENTS";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_ATTRIBUTES_OUTPUT_VALUES = "SET_ATTRIBUTES_OUTPUT_VALUES";
export const SET_EVENT_TO_TRIGGER = "SET_EVENT_TO_TRIGGER";

// Robo swarm
export const REQUEST_START_ROBO_SWARM = "REQUEST_START_ROBO_SWARM";
export const ROBO_SWARM_STARTED = "ROBO_SWARM_STARTED";
export const ROBO_SWARM_STARTED_ERROR = "ROBO_SWARM_STARTED_ERROR";

export const REQUEST_STOP_ROBO_SWARM = "REQUEST_STOP_ROBO_SWARM";
export const ROBO_SWARM_STOPPED = "ROBO_SWARM_STOPPED";

export const REQUEST_STATUS_ROBO_SWARM = "REQUEST_STATUS_ROBO_SWARM";
export const ROBO_SWARM_STATUS = "ROBO_SWARM_STATUS";

export const REQUEST_CONNECTION_TO_WEB_SOCKET =
  "REQUEST_CONNECTION_TO_WEB_SOCKET";
export const REQUEST_DISCONNETING_FROM_WEB_SOCKET =
  "REQUEST_DISCONNETING_FROM_WEB_SOCKET";
export const SEND_MESSAGE_TO_WEB_SOCKET = "SEND_MESSAGE_TO_WEB_SOCKET";
export const REDUX_WEBSOCKET_MESSAGE = "REDUX_WEBSOCKET::MESSAGE";
export const REDUX_WEBSOCKET_BROKEN = "REDUX_WEBSOCKET::BROKEN";
export const REDUX_WEBSOCKET_BEGIN_RECONNECT =
  "REDUX_WEBSOCKET::BEGIN_RECONNECT";
export const REDUX_WEBSOCKET_RECONNECT_ATTEMPT =
  "REDUX_WEBSOCKET::RECONNECT_ATTEMPT";
export const REDUX_WEBSOCKET_RECONNECTED = "REDUX_WEBSOCKET::RECONNECTED";
export const REDUX_WEBSOCKET_ERROR = "REDUX_WEBSOCKET::ERROR";
export const REDUX_WEBSOCKET_OPEN = "REDUX_WEBSOCKET::OPEN";
export const REDUX_WEBSOCKET_CLOSED = "REDUX_WEBSOCKET::CLOSED";

export const PHOENIX_SOCKET_CONNECT = "PHOENIX_SOCKET_CONNECT";
export const PHOENIX_SOCKET_CONNECTING = "PHOENIX_SOCKET_CONNECTING";
export const PHOENIX_SOCKET_CONNECTED = "PHOENIX_SOCKET_CONNECTED";
export const PHOENIX_SOCKET_DISCONNECT = "PHOENIX_SOCKET_DISCONNECT";
export const PHOENIX_SOCKET_DISCONNECTED = "PHOENIX_SOCKET_DISCONNECTED";

export const CONSOLE_PUSH_LOG = "CONSOLE_PUSH_LOG";
export const CONSOLE_TOGGLE = "CONSOLE_TOGGLE";
export const CONSOLE_RESET = "CONSOLE_RESET";
export const CONSOLE_CLEAN = "CONSOLE_CLEAN";
export const CONSOLE_RESET_WITH_ERROR = "CONSOLE_RESET_WITH_ERROR";

export const APPLICATION_DEBUG_INIT = "APPLICATION_DEBUG_INIT";
export const APPLICATION_DEBUG_INIT_OK = "APPLICATION_DEBUG_INIT_OK";
export const APPLICATION_DEBUG_START = "APPLICATION_DEBUG_START";
export const APPLICATION_DEBUG_STARTED = "APPLICATION_DEBUG_STARTED";
export const APPLICATION_DEBUG_START_ERROR = "APPLICATION_DEBUG_START_ERROR";

export const APPLICATION_DEBUG_SET_EVENT_TYPE =
  "APPLICATION_DEBUG_SET_EVENT_TYPE";
export const APPLICATION_DEBUG_TRIGGER_EVENT =
  "APPLICATION_DEBUG_TRIGGER_EVENT";
export const APPLICATION_DEBUG_TRIGGERED_EVENT =
  "APPLICATION_DEBUG_TRIGGERED_EVENT";
export const APPLICATION_DEBUG_TRIGGER_EVENT_ERROR =
  "APPLICATION_DEBUG_TRIGGER_EVENT_ERROR";

export const APPLICATION_DEBUG_STOP = "APPLICATION_DEBUG_STOP";

export const RIGHT_SIDE_MENU_TOGGLE = "RIGHT_SIDE_MENU_TOGGLE";
