import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser } from "../../store/actions/";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import StateContext from "../../contexts/stateContext";

const DefaultLayout = React.lazy(() =>
  import("../../containers/DefaultLayout")
);

class PrivateRoutes extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getUserIfNeeded();
  }

  render() {
    const { isLoading, user, error } = this.props;
    if (isLoading && user.user == null) {
      return <div> Loading...</div>;
    }

    if (error || user == null) {
      return (
        <Route
          path="/"
          name="Home"
          render={props => (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props }
              }}
            />
          )}
        ></Route>
      );
    }
    return (
      <Route
        path="/"
        name="Home"
        render={props => <DefaultLayout {...props} />}
      ></Route>
    );
  }
}

// here, we bind the redux state as component props:
const mapStateToProps = state => {
  return {
    isLoading: state.user.isLoading,
    user: state.user,
    error: state.user.error
  };
};
// and bind the action dispatch to a prop as well:
const mapDispatchToProps = dispatch => ({
  getUserIfNeeded: () => dispatch(getUser())
});

// and connect it to redux :)
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);
