import URL from './config'

const fetchSecurityProviderPage = (page = 1, name = '') => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/security_providers?page=${page}&name=${name}`)
            .then(res => {
                if (res.status > 300) {
                    return {
                        error: {
                            statusCode: res.status,
                            reason: res.statusText
                        }
                    }
                } else {
                    return res.json()
                }
            })
            .then(result => {
                if (result.error) {
                    console.log(result.errors)
                    reject(result.error);
                } else {
                    resolve(result.data);
                }
            })
    });
}

const createSecurityProvider = (security_provider) => {
    
    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/security_providers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "security_provider": security_provider
            })
        })
            .then(res => {
                if (res.status > 300) {
                    return {
                        error: {
                            statusCode: res.status,
                            reason: res.statusText
                        }
                    }
                } else {
                    return res.json()
                }
            })
            .then(result => {
                if (result.error) {
                    console.log(result.errors)
                    reject(result.error);
                } else {
                    resolve(result.data);
                }
            })
            .catch((error) => {
                reject(`${error}`);
            })

    })
}

export default { fetchSecurityProviderPage: fetchSecurityProviderPage, createSecurityProvider: createSecurityProvider }
