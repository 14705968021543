export const START_PREAPRE_NAVBAR_LIST = 'START_PREAPRE_NAVBAR_LIST';
const startPrepareNavbarList = (navbarList, user) => {
    return { type: START_PREAPRE_NAVBAR_LIST, navbarList: navbarList, user: user }
}

export const PREAPRE_NAVBAR_LIST_SUCCESS = 'PREAPRE_NAVBAR_LIST_SUCCESS';
const prepareNavbarListSuccess = (newNavBar, userId) => {
    return { type: PREAPRE_NAVBAR_LIST_SUCCESS, newNavBar: newNavBar, userId: userId }
}

const checkItems = (navigationItems, user) =>{
    const newNavigation = navigationItems.map(item =>{
      if(item.only == undefined) 
        return item
      if(item.only && user == undefined)
        return undefined
        
      if(item.only && item.only.find(i => user.groups.includes(i))){
        if(item.children){
          item.children = checkItems(item.children, user)
          return item
        }
        return item
      }else{
        return undefined
      }
    })
    return newNavigation.filter(i => i != undefined)
  }


export const prepareNavBar = (navbarList) => {
    return (dispatch, getState) => {
        dispatch(startPrepareNavbarList())
        const user = getState().user.user
        let navBarList = []
        if(user){
            navBarList = getState().navigation.navBarList[user.jti]
            if(!navBarList){
                navBarList = checkItems(navbarList, user)
            }
            dispatch(prepareNavbarListSuccess(navBarList, user.jti))
        }else{
            navBarList = getState().navigation.navBarList["guest"]
            if(!navBarList){
                navBarList = checkItems(navbarList, user)
            }
            dispatch(prepareNavbarListSuccess(navBarList, "guest"))
        }
    }
}