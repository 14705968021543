import {
  CONSOLE_PUSH_LOG,
  CONSOLE_TOGGLE,
  CONSOLE_RESET,
  CONSOLE_RESET_WITH_ERROR,
  CONSOLE_CLEAN
} from "../actionTypes";
const consoleLogReducer = (
  state = { logs: [], showConsole: false },
  action
) => {
  switch (action.type) {
    case CONSOLE_PUSH_LOG:
      return {
        ...state,
        logs: [...state.logs, parseMessage(action.log)],
        showConsole: true
      };
    case CONSOLE_TOGGLE:
      return { ...state, showConsole: !state.showConsole };
    case CONSOLE_RESET:
      return { ...state, logs: [], showConsole: false };
    case CONSOLE_CLEAN:
      return { ...state, logs: [] };
    case CONSOLE_RESET_WITH_ERROR:
      return { ...state, logs: [action.error], showConsole: true };
    default:
      return state;
  }
};

const parseMessage = log => {
  if (log.error) {
    // error message
    return `Error: ${log.error} on ${log.eventType}`;
  } else {
    return log.values.message;
  }
};

export default consoleLogReducer;
