import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_SECURITY_PROVIDERS = "FETCH_SECURITY_PROVIDERS";
export const FETCH_SECURITY_PROVIDERS_SUCCESS =
  "FETCH_SECURITY_PROVIDERS_SUCCESS";
export const FETCH_SECURITY_PROVIDERS_FAILURE =
  "FETCH_SECURITY_PROVIDERS_FAILURE";
export const START_CREATE_SECURITY_PROVIDER = "START_CREATE_SECURITY_PROVIDER";
export const CREATE_SECURITY_PROVIDER = "CREATE_SECURITY_PROVIDER";
export const CREATE_SECURITY_PROVIDER_SUCCESS =
  "CREATE_SECURITY_PROVIDER_SUCCESS";
export const CREATE_SECURITY_PROVIDER_FAILURE =
  "CREATE_SECURITY_PROVIDER_FAILURE";
export const END_CREATE_SECURITY_PROVIDER = "END_CREATE_SECURITY_PROVIDER";
export const START_UPDATE_SECURITY_PROVIDER = "START_UPDATE_SECURITY_PROVIDER";
export const UPDATE_SECURITY_PROVIDER = "UPDATE_SECURITY_PROVIDER";
export const UPDATE_SECURITY_PROVIDER_SUCCESS =
  "UPDATE_SECURITY_PROVIDER_SUCCESS";
export const UPDATE_SECURITY_PROVIDER_FAILURE =
  "UPDATE_SECURITY_PROVIDER_FAILURE";
export const END_UPDATE_SECURITY_PROVIDER = "END_UPDATE_SECURITY_PROVIDER";

const fetchSecurityProviders = () => {
  return { type: FETCH_SECURITY_PROVIDERS, error: null };
};

const fetchSecurityProvidersSucces = json => {
  return {
    type: FETCH_SECURITY_PROVIDERS_SUCCESS,
    securityProviders: json,
    error: null
  };
};

const fetchSecurityProvidersFailure = error => {
  return { type: FETCH_SECURITY_PROVIDERS_FAILURE, error: error };
};

const createSecurityProviderRequest = () => {
  return { type: CREATE_SECURITY_PROVIDER, error: null };
};

const createSecurityProviderSucces = json => {
  return {
    type: CREATE_SECURITY_PROVIDER_SUCCESS,
    securityProvider: json,
    error: null
  };
};

const createSecurityProviderFailure = error => {
  return { type: CREATE_SECURITY_PROVIDER_FAILURE, error: error };
};

const updateSecurityProviderRequest = () => {
  return { type: UPDATE_SECURITY_PROVIDER, error: null };
};

const updateSecurityProviderSucces = json => {
  return {
    type: UPDATE_SECURITY_PROVIDER_SUCCESS,
    securityProvider: json,
    error: null
  };
};

const updateSecurityProviderFailure = error => {
  return { type: UPDATE_SECURITY_PROVIDER_FAILURE, error: error };
};

export const startUpdateSecurityProvider = updateItem => {
  return { type: START_UPDATE_SECURITY_PROVIDER, updateItem: updateItem };
};

export const startCreateSecurityProvider = () => {
  return { type: START_CREATE_SECURITY_PROVIDER };
};

export const endUpdateSecurityProvider = () => {
  return { type: END_UPDATE_SECURITY_PROVIDER };
};

export const endCreateSecurityProvider = () => {
  return { type: END_CREATE_SECURITY_PROVIDER };
};

export const fetchSecurityProviderList = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchSecurityProviders());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/security_providers`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchSecurityProvidersSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchSecurityProvidersFailure(error));
        }
      });
  };
};

export const createSecurityProvider = params => {
  return (dispatch, getState) => {
    dispatch(createSecurityProviderRequest());
    const user = getState().user.user;

    return axios
      .post(`${endPoint}/api/v1/security_providers`, params, {
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(createSecurityProviderSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(createSecurityProviderFailure(error));
        }
      });
  };
};

export const updateSecurityProvider = (securityProvider, params) => {
  return (dispatch, getState) => {
    dispatch(updateSecurityProviderRequest());
    const user = getState().user.user;

    return axios
      .put(
        `${endPoint}/api/v1/security_providers/${securityProvider.id}`,
        params,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
      .then(response => {
        dispatch(updateSecurityProviderSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(updateSecurityProviderFailure(error));
        }
      });
  };
};
