import { RIGHT_SIDE_MENU_TOGGLE } from "../actionTypes";
const rightSideMenuReducer = (state = { activeTab: "1" }, action) => {
  switch (action.type) {
    case RIGHT_SIDE_MENU_TOGGLE:
      return {
        ...state,
        activeTab: action.tab
      };
    default:
      return state;
  }
};

export default rightSideMenuReducer;
