import URL from './config'

//const fetchDeviceTypesPage = (page = 1,name = '',version='', status='active') => {
const fetchDeviceTypesPage = (page = 1,args) => { // 'args' is an object with [name,versio,status] options

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types?page=${page}
                            &name=${args === undefined || args.name === undefined?'':args.name}
                            &version=${args === undefined || args.version === undefined?'':args.version}
                            &state=${args === undefined || args.status === undefined?'':args.status}`
            )
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{
              resolve(result.data);
          }
        })
      });
}

const showDeviceType = (id) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types/${id}`)
        .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
        })
        .then(result => {
          if(result.error) {
              console.log(result.errors)
              reject(result.error);
          }else{
              resolve(result.data);
          }
        })
      });
}

const createDeviceType = (device_type) => {

    return new Promise((resolve, reject) => {
        fetch(`${URL.BASE_URL}/api/v1/device_types`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
             "device_type": device_type
            })
        })
        .then(res => {
            if(res.status > 300){
                return {
                    error: {
                        statusCode: res.status,
                        reason: res.statusText
                    }
                }
            }else{
                return res.json()
            }
        })
        .then(result => {
            if(result.error) {
                console.log(result)
                reject(result.error);
            }else{
                resolve(result.data);
            }
        })
        .catch((error) =>{
            reject(`${error}`);
          })

    })
}

const countObjects = (id) => {
  return new Promise((resolve, reject) => {
      fetch(`${URL.BASE_URL}/api/v1/device_types/${id}/count_objects`, {
          method: 'GET',
          headers: {'Content-Type':'application/json'},
      })
      .then(res => {
          if(res.status > 300){
              return {
                  error: {
                      statusCode: res.status,
                      reason: res.statusText
                  }
              }
          }else{
              return res.json()
          }
      })
      .then(result => {
          if(result.error) {
              console.log(result)
              reject(result.error);
          }else{
              resolve(result);
          }
      })
      .catch((error) =>{
          reject(`${error}`);
        })

  })
}

export default {fetchDeviceTypesPage: fetchDeviceTypesPage, showDeviceType: showDeviceType, createDeviceType: createDeviceType, countObjects:countObjects}
