const chartSimple = {
  offset: {
    x: 0,
    y: 0
  },
  nodes: {},
  links: {},
  selected: {},
  hovered: {},
  records: {},
  metadata: {
    device_type: {
      base_field: undefined,
      protocol_id: undefined
    }
  },
  envvars: [],
  updated: false
};
export default chartSimple;
