import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import StateProvider from "./contexts/stateProvider";
import StateContext from "./contexts/stateContext";
import PrivateRoutes from "./components/privateRoutes";
import Token from "./views/Token/Token";

import "./App.scss";
import "../node_modules/react-grid-layout/css/styles.css";
import "..//node_modules/react-resizable/css/styles.css";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
// const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
// const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

class App extends Component {
  static contextType = StateContext;

  render() {
    return (
      <StateProvider>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/token/:id"
                name="token"
                render={props => <Token {...props} />}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <Login {...props} />}
              />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />

              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <PrivateRoutes></PrivateRoutes>
              {/* <Route path="/" name="Home" render={props => this.context.auth.isAuthenticated() ? <DefaultLayout {...props} /> : <Redirect
                to={{
                  pathname: "/login",
                  // state: { from: location }
                }}
              />} /> */}
            </Switch>
          </React.Suspense>
        </HashRouter>
      </StateProvider>
    );
  }
}
export default App;
