import {
    REQUEST_CONNECTION_TO_WEB_SOCKET,
    REQUEST_DISCONNETING_FROM_WEB_SOCKET,
    SEND_MESSAGE_TO_WEB_SOCKET,
    REDUX_WEBSOCKET_BEGIN_RECONNECT,
    REDUX_WEBSOCKET_ERROR,
    REDUX_WEBSOCKET_BROKEN,
    REDUX_WEBSOCKET_MESSAGE,
    REDUX_WEBSOCKET_RECONNECTED,
    REDUX_WEBSOCKET_RECONNECT_ATTEMPT,
    REDUX_WEBSOCKET_OPEN,
    REDUX_WEBSOCKET_CLOSED,

} from '../actionTypes'

const webSocketReducer = (state = { isConnected: false, isLoading: false, messages: [], timerRunning: false }, action) => {
    switch (action.type) {

        case REQUEST_CONNECTION_TO_WEB_SOCKET:
            return { ...state, isLoading: true, };
        case REDUX_WEBSOCKET_OPEN:
            return { ...state, isLoading: false, isConnected: true };
        case REDUX_WEBSOCKET_CLOSED:
            return { ...state, isLoading: false, isConnected: false };
        case REQUEST_DISCONNETING_FROM_WEB_SOCKET:
            return { ...state, isLoading: true };
        case SEND_MESSAGE_TO_WEB_SOCKET:
            return { ...state, messageSent: true };
        case REDUX_WEBSOCKET_MESSAGE:
            return {
                ...state, messageReceived: true, message: action.payload.message,
                timestamp: action.meta.timestamp,
                messages: filterMessage(action.payload.message, action.meta.timestamp, state.messages)
            }
        default:
            return state;
    }
}

const filterMessage = (message, timeStamp, messages) => {
    if (message != 'pong') {
        return [{ message: message, timestamp: timeStamp }, ...messages]
    }
    return messages
}



export default webSocketReducer