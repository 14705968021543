import {
    FETCH_NETWORK_PROTOCOLRS,
    FETCH_NETWORK_PROTOCOLRS_FAILURE,
    FETCH_NETWORK_PROTOCOLRS_SUCCESS,
    CREATE_NETWORK_PROTOCOLR,
    CREATE_NETWORK_PROTOCOLR_SUCCESS,
    CREATE_NETWORK_PROTOCOLR_FAILURE,
    UPDATE_NETWORK_PROTOCOLR,
    UPDATE_NETWORK_PROTOCOLR_SUCCESS,
    UPDATE_NETWORK_PROTOCOLR_FAILURE,
    START_CREATE_NETWORK_PROTOCOLR,
    START_UPDATE_NETWORK_PROTOCOLR,
    END_UPDATE_NETWORK_PROTOCOLR,
    END_CREATE_NETWORK_PROTOCOLR
} from '../actions/'

const networkProtocolReducer = (state = { isLoading: false, items: [], error: null, isCreating: false, isUpdating: false }, action) => {
    switch (action.type) {
        case FETCH_NETWORK_PROTOCOLRS:
            return { ...state, isLoading: true, error: null, items: [] };
        case FETCH_NETWORK_PROTOCOLRS_SUCCESS:
            return { ...state, isLoading: false, items: action.networkProtocols.data, meta: action.networkProtocols.meta, error: null };
        case FETCH_NETWORK_PROTOCOLRS_FAILURE:
            return { ...state, isLoading: false, error: action.error, items: [] };
        case CREATE_NETWORK_PROTOCOLR:
            return { ...state, isCreateLoading: true, error: null, item: null }
        case CREATE_NETWORK_PROTOCOLR_SUCCESS:
            return { ...state, isCreateLoading: false, error: null, items: [...state.items, action.networkProtocol.data], item: action.networkProtocol.data, isCreating: false }
        case CREATE_NETWORK_PROTOCOLR_FAILURE:
            return { ...state, isCreateLoading: false, error: action.error, item: null, isCreating: false }
        case UPDATE_NETWORK_PROTOCOLR:
            return { ...state, isLoading: true, error: null, item: null  }
        case UPDATE_NETWORK_PROTOCOLR_SUCCESS:
            return { ...state, isLoading: false, error: null, item: action.networkProtocol.data, items: findAndReplace(state.items, action.networkProtocol.data), isUpdating: false, updateItem: null }
        case UPDATE_NETWORK_PROTOCOLR_FAILURE:
            return { ...state, isLoading: false, error: action.error, item: null, isUpdating: false  }
        case START_UPDATE_NETWORK_PROTOCOLR:
            return { ...state, isUpdating: true , updateItem: action.updateItem }
        case START_CREATE_NETWORK_PROTOCOLR: 
            return { ...state, isCreating: true, updateItem: null  }
        case END_UPDATE_NETWORK_PROTOCOLR:
            return { ...state, isUpdating: false }
        case END_CREATE_NETWORK_PROTOCOLR: 
            return { ...state, isCreating: false, updateItem: null  }
        default:
            return state;
    }
}

const findAndReplace = (items, newItem) => {
    const index = items.findIndex(i => i.id == newItem.id)
    if(index == -1){
        return [...items, newItem]
    }
    
    return items.map((element, i) => i == index ? newItem : element)
}
export default networkProtocolReducer