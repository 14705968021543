import React, { Component } from "react";
import StateContext from "./stateContext";
import i18n from "../i18n";

import manageUnits from "./manageUnits";
import manageProtocols from "./manageProtocols";
import manageFlowCharts from "./manageFlowCharts";
import manageNetworkProtocols from "./manageNetworkProtocols";
import manageSecurityProvider from "./manageSecurityProvider";
import manageDeviceTypes from "./manageDeviceTypes";
import manageBaseFields from "./manageBaseFields";
import manageAttributes from "./manageAttributes";
import manageActions from "./manageActions";
import manageObjects from "./manageObjects";
import manageValueTypes from "./manageValueTypes";
import manageImages from "./manageImages";
import manageCompiler from "./manageCompiler";
import manageState from "./manageState";
import managePheripheralConfiguration from "./managePeripheralConfigurations";
import manageAltiorApplicationPublish from "./manageAltiorApplicationPublish";
import manageAuth from "./manageAuth";

//const BASE_URL = "http://staging.inkwelldata.com:4001";

class StateProvider extends Component {
  state = {
    lang: "en",
    units: [],
    protocols: [],
    flowcharts: [],
    network_protocols: [],
    security_providers: [],
    device_types: [],
    base_fields: [],
    attributes: [],
    objects: [],
    value_types: [],
    peripheral_configurations: [],
    state: []
  };

  handleLanguage = newLang => {
    this.setState({
      lang: newLang
    });
    i18n.changeLanguage(newLang);
  };

  render() {
    return (
      <StateContext.Provider
        value={{
          altiorChangeLang: this.handleLanguage,

          units: {
            fetch: manageUnits.fetchUnitsPage,
            create: manageUnits.createUnit,
            set: values => this.setState({ units: values }),
            get: this.state.units
          },
          protocols: {
            fetch: manageProtocols.fetchProtocolsPage,
            create: manageProtocols.createProtocol,
            set: values => this.setState({ protocols: values }),
            get: this.state.protocols
          },
          flowcharts: {
            fetch: manageFlowCharts.fetchFlowChartsPage,
            create: manageFlowCharts.createFlowChart,
            show: manageFlowCharts.showFlowChart,
            update: manageFlowCharts.updateFlowChart,
            set: values => this.setState({ flowcharts: values }),
            get: this.state.flowcharts
          },
          network_protocols: {
            fetchWithParams:
              manageNetworkProtocols.fetchNetworkProtocolsPageWithParams,
            fetch: manageNetworkProtocols.fetchNetworkProtocolsPage,
            create: manageNetworkProtocols.createNetworkProtocol,
            set: values => this.setState({ network_protocols: values }),
            get: this.state.network_protocols
          },
          network_adapters: {
            fetchWithParams:
              manageNetworkProtocols.fetchNetworkProtocolsPageWithParams,
            fetch: manageNetworkProtocols.fetchNetworkProtocolsPage,
            create: manageNetworkProtocols.createNetworkProtocol,
            set: values => this.setState({ network_adapters: values }),
            get: this.state.network_adapters
          },
          security_providers: {
            fetch: manageSecurityProvider.fetchSecurityProviderPage,
            create: manageSecurityProvider.createSecurityProvider,
            set: values => this.setState({ security_providers: values }),
            get: this.state.security_providers
          },
          device_types: {
            fetch: manageDeviceTypes.fetchDeviceTypesPage,
            create: manageDeviceTypes.createDeviceType,
            set: values => this.setState({ device_types: values }),
            get: this.state.device_types,
            show: manageDeviceTypes.showDeviceType,
            countObjects: manageDeviceTypes.countObjects
          },
          base_fields: {
            fetch: manageBaseFields.fetchBaseFieldsPage,
            set: values => this.setState({ base_fields: values }),
            get: this.state.base_fields
          },
          attributes: {
            fetch: manageAttributes.fetchAttributesPage,
            get: this.state.attributes
          },
          actions: {
            fetch: manageActions.fetchActionsPage,
            get: this.state.actions
          },
          objects: {
            fetch: manageObjects.fetchObjectsPage,
            create: manageObjects.createObject,
            set: values => this.setState({ objects: values }),
            get: this.state.Objects,
            show: manageObjects.showObject
          },
          value_types: {
            fetch: manageValueTypes.fetchValueTypesPage,
            get: this.state.valuetypes
          },
          images: {
            create: manageImages.createImage,
            show: manageImages.showImage,
            get: this.state.valuetypes
          },
          compiler: {
            compile: manageCompiler.compile,
            compilationHistory: manageCompiler.compilationHistory
          },
          state: {
            create: manageState.createState
          },
          peripheral_configurations: {
            fetchWithParams:
              managePheripheralConfiguration.fetchPeripheralConfigurationsPageWithParams,
            fetch:
              managePheripheralConfiguration.fetchPeripheralConfigurationsPage,
            create:
              managePheripheralConfiguration.createPeripheralConfiguration,
            set: values => this.setState({ peripheral_configurations: values }),
            get: this.state.peripheral_configurations
          },
          altior_application: {
            publish: manageAltiorApplicationPublish.publishAltiorApplication,
            stop: manageAltiorApplicationPublish.stopAltiorApplication
          },
          auth: {
            authenticate: manageAuth.authenticate,
            isAuthenticated: manageAuth.isAuthenticated,
            logout: manageAuth.logout
          }
        }}
      >
        {this.props.children}
      </StateContext.Provider>
    );
  }
}
export default StateProvider;
