import axios from "axios";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_VALUE_TYPE = "FETCH_VALUE_TYPE";
const fetchValueTypes = () => {
  return { type: FETCH_VALUE_TYPE };
};

export const FETCH_VALUE_TYPE_SUCCESS = "FETCH_VALUE_TYPE_SUCCESS";
const fetchValueTypesSucces = json => {
  return { type: FETCH_VALUE_TYPE_SUCCESS, items: json, error: null };
};

export const FETCH_VALUE_TYPE_FAILURE = "FETCH_VALUE_TYPE_FAILURE";
const fetchValueTypesFailure = error => {
  return { type: FETCH_VALUE_TYPE_FAILURE, error: error };
};

export const fetchValueTypeslList = () => {
  return (dispatch, getState) => {
    dispatch(fetchValueTypes());
    const items = getState().valueTypes.items;

    if (items && items.length > 0) {
      dispatch(fetchValueTypesSucces(items));
    } else {
      const user = getState().user.user;
      return axios
        .get(`${endPoint}/api/v1/value_types`, {
          headers: { Authorization: `Bearer ${user.accessToken}` }
        })
        .then(response => {
          dispatch(fetchValueTypesSucces(response.data));
        })
        .catch(error => {
          if (checkErrorResponse(dispatch, user, error)) {
            dispatch(fetchValueTypesFailure(error));
          }
        });
    }
  };
};
