import {
  CONSOLE_PUSH_LOG,
  CONSOLE_TOGGLE,
  CONSOLE_RESET,
  CONSOLE_RESET_WITH_ERROR,
  CONSOLE_CLEAN
} from "../actionTypes";

export const consolePushLog = log => ({
  type: CONSOLE_PUSH_LOG,
  log: log
});

export const consoleToggle = () => ({
  type: CONSOLE_TOGGLE
});

export const consoleRest = () => ({
  type: CONSOLE_RESET
});

export const consoleClean = () => ({
  type: CONSOLE_CLEAN
});

export const consoleRestWithError = error => ({
  type: CONSOLE_RESET_WITH_ERROR,
  error: error
});
