import {
  FETCH_DEVICE_TYPES,
  FETCH_DEVICE_TYPES_FAILURE,
  FETCH_DEVICE_TYPES_SUCCESS,
  CREATE_DEVICE_TYPE,
  CREATE_DEVICE_TYPE_SUCCESS,
  CREATE_DEVICE_TYPE_FAILURE,
  UPDATE_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE_SUCCESS,
  UPDATE_DEVICE_TYPE_FAILURE,
  START_CREATE_DEVICE_TYPE,
  START_UPDATE_DEVICE_TYPE,
  END_UPDATE_DEVICE_TYPE,
  END_CREATE_DEVICE_TYPE,
  FETCH_DEVICE_TYPE,
  FETCH_DEVICE_TYPE_FAILURE,
  FETCH_DEVICE_TYPE_SUCCESS,
  CLEAR_CURRENT_DEVICE_TYPE
} from "../actions/";

const deviceTypeReducer = (
  state = {
    isLoading: false,
    items: [],
    error: null,
    isCreating: false,
    isUpdating: false,
    isUpdated: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_DEVICE_TYPES:
      return {
        ...state,
        isLoading: true,
        error: null,
        items: [],
        isUpdated: false
      };
    case FETCH_DEVICE_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.deviceTypes.data,
        meta: action.deviceTypes.meta,
        isUpdated: false
      };
    case FETCH_DEVICE_TYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        items: [],
        isUpdated: false
      };
    case CREATE_DEVICE_TYPE:
      return {
        ...state,
        isCreateLoading: true,
        error: null,
        item: null,
        isCreated: false,
        isUpdated: false
      };
    case CREATE_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        error: null,
        items: [...state.items, action.deviceType.data],
        item: action.deviceType.data,
        deviceType: action.deviceType.data,
        isCreating: false,
        isCreated: true,
        isUpdated: false
      };
    case CREATE_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        isCreateLoading: false,
        error: action.error,
        item: null,
        isCreating: false,
        isCreated: false,
        isUpdated: false
      };
    case UPDATE_DEVICE_TYPE:
      return {
        ...state,
        isLoading: true,
        error: null,
        item: null,
        isUpdated: false
      };
    case UPDATE_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        item: action.deviceType.data,
        items: findAndReplace(state.items, action.deviceType.data),
        isUpdated: true,
        isUpdating: false,
        updateItem: null
      };
    case UPDATE_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        item: null,
        isUpdating: false,
        isUpdated: false
      };
    case START_UPDATE_DEVICE_TYPE:
      return {
        ...state,
        isCreated: false,
        isCreating: false,
        isUpdating: true,
        updateItem: action.updateItem
      };
    case START_CREATE_DEVICE_TYPE:
      return { ...state, isCreating: true, updateItem: null, deviceType: null };
    case END_UPDATE_DEVICE_TYPE:
      return { ...state, isUpdating: false };
    case END_CREATE_DEVICE_TYPE:
      return { ...state, isCreating: false, updateItem: null };
    case FETCH_DEVICE_TYPE:
      return {
        ...state,
        deviceType: null,
        isFetchDeviceType: true,
        error: null,
        isUpdated: false
      };
    case FETCH_DEVICE_TYPE_FAILURE:
      return {
        ...state,
        deviceType: null,
        isFetchDeviceType: false,
        error: action.error,
        isUpdated: false
      };
    case FETCH_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        deviceType: action.deviceType.data,
        isFetchDeviceType: false,
        error: null,
        isUpdated: false
      };
    case CLEAR_CURRENT_DEVICE_TYPE:
      return {
        ...state,
        deviceType: null
      };
    default:
      return state;
  }
};

const findAndReplace = (items, newItem) => {
  const index = items.findIndex(i => i.id == newItem.id);
  if (index == -1) {
    return [...items, newItem];
  }

  return items.map((element, i) => (i == index ? newItem : element));
};
export default deviceTypeReducer;
