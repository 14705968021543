import axios from "axios";
import { tokenExpired } from "./token";
import { deleteUser } from "./user";
import { checkErrorResponse } from "../../utils/checkErrorResonse";
const endPoint = process.env.REACT_APP_BASE_URL;

export const FETCH_BASE_FIELDS = "FETCH_BASE_FIELDS";
export const FETCH_BASE_FIELDS_SUCCESS = "FETCH_BASE_FIELDS_SUCCESS";
export const FETCH_BASE_FIELDS_FAILURE = "FETCH_BASE_FIELDS_FAILURE";

const fetchBaseFields = () => {
  return { type: FETCH_BASE_FIELDS, error: null };
};

const fetchBaseFieldsSucces = json => {
  return { type: FETCH_BASE_FIELDS_SUCCESS, BaseFields: json, error: null };
};

const fetchBaseFieldsFailure = error => {
  return { type: FETCH_BASE_FIELDS_FAILURE, error: error };
};

export const fetchBaseFieldList = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchBaseFields());
    const user = getState().user.user;
    return axios
      .get(`${endPoint}/api/v1/base_fields`, {
        params: params,
        headers: { Authorization: `Bearer ${user.accessToken}` }
      })
      .then(response => {
        dispatch(fetchBaseFieldsSucces(response.data));
      })
      .catch(error => {
        if (checkErrorResponse(dispatch, user, error)) {
          dispatch(fetchBaseFieldsFailure(error));
        }
      });
  };
};
