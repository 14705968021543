import {
    FETCH_SECURITY_PROVIDERS,
    FETCH_SECURITY_PROVIDERS_FAILURE,
    FETCH_SECURITY_PROVIDERS_SUCCESS,
    CREATE_SECURITY_PROVIDER,
    CREATE_SECURITY_PROVIDER_SUCCESS,
    CREATE_SECURITY_PROVIDER_FAILURE,
    UPDATE_SECURITY_PROVIDER,
    UPDATE_SECURITY_PROVIDER_SUCCESS,
    UPDATE_SECURITY_PROVIDER_FAILURE,
    START_CREATE_SECURITY_PROVIDER,
    START_UPDATE_SECURITY_PROVIDER,
    END_UPDATE_SECURITY_PROVIDER,
    END_CREATE_SECURITY_PROVIDER
} from '../actions/'

const securityProviderReducer = (state = { isLoading: false, items: [], error: null, isCreating: false, isUpdating: false }, action) => {
    switch (action.type) {
        case FETCH_SECURITY_PROVIDERS:
            return { ...state, isLoading: true, error: null, items: [] };
        case FETCH_SECURITY_PROVIDERS_SUCCESS:
            return { ...state, isLoading: false, items: action.securityProviders.data, meta: action.securityProviders.meta };
        case FETCH_SECURITY_PROVIDERS_FAILURE:
            return { ...state, isLoading: false, error: action.error, items: [] };
        case CREATE_SECURITY_PROVIDER:
            return { ...state, isCreateLoading: true, error: null, item: null }
        case CREATE_SECURITY_PROVIDER_SUCCESS:
            return { ...state, isCreateLoading: false, error: null, items: [...state.items, action.securityProvider.data], item: action.securityProvider.data, isCreating: false }
        case CREATE_SECURITY_PROVIDER_FAILURE:
            return { ...state, isCreateLoading: false, error: action.error, item: null, isCreating: false }
        case UPDATE_SECURITY_PROVIDER:
            return { ...state, isLoading: true, error: null, item: null  }
        case UPDATE_SECURITY_PROVIDER_SUCCESS:
            return { ...state, isLoading: false, error: null, item: action.securityProvider.data, items: findAndReplace(state.items, action.securityProvider.data), isUpdating: false, updateItem: null }
        case UPDATE_SECURITY_PROVIDER_FAILURE:
            return { ...state, isLoading: false, error: action.error, item: null, isUpdating: false  }
        case START_UPDATE_SECURITY_PROVIDER:
            return { ...state, isUpdating: true , updateItem: action.updateItem }
        case START_CREATE_SECURITY_PROVIDER: 
            return { ...state, isCreating: true, updateItem: null  }
        case END_UPDATE_SECURITY_PROVIDER:
            return { ...state, isUpdating: false }
        case END_CREATE_SECURITY_PROVIDER: 
            return { ...state, isCreating: false, updateItem: null  }
        default:
            return state;
    }
}

const findAndReplace = (items, newItem) => {
    const index = items.findIndex(i => i.id == newItem.id)
    if(index == -1){
        return [...items, newItem]
    }
    
    return items.map((element, i) => i == index ? newItem : element)
}
export default securityProviderReducer