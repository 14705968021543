import {
  LOGIN_REQUEST,
  START_SAVING_TOKEN,
  END_SAVING_TOKEN,
  START_GET_USER,
  END_GET_USER,
  END_GET_USER_ERROR,
  START_DELETE_USER,
  END_DELETE_USER,
  TOKEN_EXPIRED,
  START_LOGOUT,
  END_LOGOUT,
  ERROR_LOGOUT,
  SET_REDIRECT_URL,
  SET_REDIRECT_URL_SUCCESS,
  GET_REDIRECT_URL,
  GET_REDIRECT_URL_SUCCESS
} from "../actions/";

export default (
  state = {
    from_path: "/",
    isSaving: true,
    isLoading: false,
    tokenExpired: false,
    redirectUrl: "/"
  },
  action
) => {
  switch (action.type) {
    case TOKEN_EXPIRED:
      return { ...state, tokenExpired: true };
    case LOGIN_REQUEST:
      return { ...state, from_path: action.user.from_path };
    case START_SAVING_TOKEN:
      return { ...state, isSaving: true };
    case END_SAVING_TOKEN:
      return {
        ...state,
        isSaving: false,
        user: action.user,
        tokenExpired: false
      };
    case START_GET_USER:
      return { ...state, isLoading: true };
    case END_GET_USER:
      return {
        ...state,
        isLoading: false,
        user: action.user,
        error: false,
        tokenExpired: false
      };
    case END_GET_USER_ERROR:
      return { ...state, isLoading: false, error: true };
    case START_DELETE_USER:
      return { ...state, isDeleteing: true };
    case END_DELETE_USER:
      return { ...state, isDeleteing: false, deleted: true, user: null };
    case START_LOGOUT:
      return { ...state, isLoggingOut: true };
    case END_LOGOUT:
      return { ...state, isLoggingOut: false, user: null, deleted: true };
    case ERROR_LOGOUT:
      return { ...state, isLoggingOut: false };
    case SET_REDIRECT_URL:
      return { ...state, isSaving: true };
    case SET_REDIRECT_URL_SUCCESS:
      return { ...state, isSaving: false };
    case GET_REDIRECT_URL:
      return { ...state, isLoading: true };
    case GET_REDIRECT_URL_SUCCESS:
      return { ...state, isLoading: false, redirectUrl: action.redirectUrl };
    default:
      return state;
  }
};
