import jwtDecode from "jwt-decode";
import axios from "axios";
const endPoint = process.env.REACT_APP_BASE_URL;

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const SET_REDIRECT_URL_SUCCESS = "SET_REDIRECT_URL_SUCCESS";
export const GET_REDIRECT_URL = "GET_REDIRECT_URL";
export const GET_REDIRECT_URL_SUCCESS = "GET_REDIRECT_URL_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
const loginRequest = (from_path = "/") => {
  return { type: LOGIN_REQUEST, from_path: from_path };
};

export const START_SAVING_TOKEN = "START_SAVING_TOKEN";
const startSavingToken = () => {
  return { type: START_SAVING_TOKEN };
};

export const END_SAVING_TOKEN = "END_SAVING_TOKEN";
const endSavingToken = json => {
  return { type: END_SAVING_TOKEN, user: json };
};

export const saveToken = jwt => {
  return dispatch => {
    dispatch(startSavingToken());
    return new Promise((resolve, reject) => {
      let decoded = jwtDecode(jwt);
      decoded["accessToken"] = jwt;
      localStorage.setItem("user", JSON.stringify(decoded));
      dispatch(endSavingToken(decoded));
      resolve(decoded);
    });
  };
};

export const START_GET_USER = "START_GET_USER";
const startGetUser = () => {
  return { type: START_GET_USER };
};

export const END_GET_USER = "END_GET_USER";
const endGetUser = user => {
  return { type: END_GET_USER, user: user };
};

export const END_GET_USER_ERROR = "END_GET_USER_ERROR";
const endGetUserError = () => {
  return { type: END_GET_USER_ERROR };
};

const setRedirectUrlAction = () => {
  return { type: SET_REDIRECT_URL };
};

const setRedirectUrlSuccess = () => {
  return { type: SET_REDIRECT_URL_SUCCESS };
};

const getRedirectUrlAction = () => {
  return { type: GET_REDIRECT_URL };
};

const getRedirectUrlSuccess = url => {
  return { type: GET_REDIRECT_URL_SUCCESS, redirectUrl: url };
};

export const getRedirectUrl = () => {
  return dispatch => {
    dispatch(getRedirectUrlAction());
    const url = localStorage.getItem("redirectUrl");
    dispatch(getRedirectUrlSuccess(url ?? "/"));
  };
};

export const setRedirectUrl = url => {
  return dispatch => {
    dispatch(setRedirectUrlAction());
    localStorage.setItem("redirectUrl", url);
    dispatch(setRedirectUrlSuccess());
  };
};

export const getUser = () => {
  return dispatch => {
    const user = localStorage.getItem("user");
    if (user != null) {
      dispatch(endGetUser(JSON.parse(user)));
    } else {
      dispatch(endGetUserError());
    }
  };
};

export const getUserIfNeeded = () => {
  return (dispatch, getState) => {
    const userState = getState().user;
    if (userState.user == null) {
      dispatch(startGetUser());
      getUser();
    }
  };
};

export const START_DELETE_USER = "START_DELETE_USER";
const startDeleteUser = () => {
  return { type: START_DELETE_USER };
};

export const END_DELETE_USER = "END_DELETE_USER";
const endDeletetUser = () => {
  return { type: END_DELETE_USER };
};

export const deleteUser = () => {
  return dispatch => {
    dispatch(startDeleteUser());
    const user = localStorage.removeItem("user");
    dispatch(endDeletetUser());
  };
};

export const START_LOGOUT = "START_LOGOUT";
const startLogout = () => {
  return { type: START_LOGOUT };
};

export const END_LOGOUT = "END_LOGOUT";
const endLogout = () => {
  return { type: END_LOGOUT };
};

export const ERROR_LOGOUT = "ERROR_LOGOUT";
const errorLogout = () => {
  return { type: ERROR_LOGOUT };
};

export const logout = () => {
  return (dispatch, getState) => {
    dispatch(startLogout());
    const user = getState().user.user;

    return axios
      .post(
        `${endPoint}/api/v1/logout`,
        {},
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
      .then(result => {
        localStorage.removeItem("user");
        dispatch(endLogout());
      })
      .catch(error => dispatch(errorLogout(error)));
  };
};
