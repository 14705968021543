import {
  ON_DRAG_NODE,
  ON_DRAG_CANVAS,
  ON_LINK_START,
  ON_LINK_MOVE,
  ON_LINK_COMPLETE,
  ON_LINK_CANCEL,
  ON_LINK_MOUSE_ENTER,
  ON_LINK_MOUSE_LEAVE,
  ON_LINK_CLICK,
  ON_CANVAS_CLICK,
  ON_DELETE_KEY,
  ON_NODE_CLICK,
  ON_NODE_SIZE_CHANGE,
  ON_PORT_POSITION_CHANGE,
  ON_CANVAS_DROP,
  DUMP_STATE,
  ON_INPUT_CHANGE,
  LOAD_JSON_CHART,
  SET_JSON_CHART,
  ADD_NEW_RECORD,
  SET_ENVVARS,
  SET_METADATA,
  DEL_ENVVAR,
  ADD_ENVVAR,
  CHART_SAVE
} from "../actionTypes";
import { v4 } from "uuid";

import chartSimple from "../exampleChartState";

const initialState = chartSimple;

const chart = (state = initialState, action) => {
  switch (action.type) {
    case ON_DRAG_NODE: {
      const { config, data, id } = action.payload;
      const newstate = { ...state };
      const nodechart = newstate.nodes[id];

      if (nodechart) {
        newstate.updated = true;
        newstate.nodes[id].position =
          config && config.snapToGrid
            ? {
                x: Math.round(data.x / 20) * 20,
                y: Math.round(data.y / 20) * 20
              }
            : { x: data.x, y: data.y };
      }
      return newstate;
    }
    case ON_DRAG_CANVAS: {
      const { config, data } = action.payload;
      const newstate = { ...state };
      newstate.updated = true;
      newstate.offset =
        config && config.snapToGrid
          ? { x: Math.round(data.x / 20) * 20, y: Math.round(data.y / 20) * 20 }
          : { x: data.x, y: data.y };
      return newstate;
    }
    case ON_LINK_START: {
      const { linkId, fromNodeId, fromPortId } = action.payload;
      const newstate = { ...state };
      newstate.updated = true;
      newstate.links[linkId] = {
        id: linkId,
        from: {
          nodeId: fromNodeId,
          portId: fromPortId
        },
        to: {}
      };
      return newstate;
    }
    case ON_LINK_MOVE: {
      const { linkId, toPosition } = action.payload;
      const newstate = { ...state };
      newstate.updated = true;
      const link = newstate.links[linkId];
      link.to.position = toPosition;
      newstate.links[linkId] = { ...link };
      return newstate;
    }
    case ON_LINK_COMPLETE: {
      const {
        linkId,
        fromNodeId,
        fromPortId,
        toNodeId,
        toPortId,
        config = {}
      } = action.payload;
      const newstate = { ...state };
      newstate.updated = true;
      // Original validateLink
      if (
        (config.validateLink
          ? config.validateLink({ ...action.payload, newstate })
          : true) &&
        [fromNodeId, fromPortId].join() !== [toNodeId, toPortId].join()
      ) {
        newstate.links[linkId].to = {
          nodeId: toNodeId,
          portId: toPortId
        };
      } else {
        delete newstate.links[linkId];
      }

      // custom validate Link (from output to input)
      if (
        newstate.nodes[fromNodeId].ports[fromPortId].properties.custom ===
        newstate.nodes[toNodeId].ports[toPortId].properties.custom
      ) {
        delete newstate.links[linkId];
        return newstate;
      }

      // Custom validate Flow (links are created according to Input and Ouput ports)
      if (
        newstate.nodes[fromNodeId].ports[fromPortId].properties.custom ===
        "Input"
      ) {
        newstate.links[linkId].from.nodeId = toNodeId;
        newstate.links[linkId].from.portId = toPortId;
        newstate.links[linkId].to.nodeId = fromNodeId;
        newstate.links[linkId].to.portId = fromPortId;
      }

      return newstate;
    }
    case ON_LINK_CANCEL: {
      const newstate = { ...state };
      newstate.updated = true;
      const { linkId } = action.payload;
      delete newstate.links[linkId];
      return newstate;
    }
    case ON_LINK_MOUSE_ENTER: {
      const newstate = { ...state };
      newstate.updated = true;
      const { linkId } = action.payload;

      const link = newstate.links[linkId];
      // Set the connected ports to hover
      if (link.to.nodeId && link.to.portId) {
        if (
          newstate.hovered.type !== "link" ||
          newstate.hovered.id !== linkId
        ) {
          newstate.hovered = {
            type: "link",
            id: linkId
          };
        }
      }
      return newstate;
    }
    case ON_LINK_MOUSE_LEAVE: {
      const newstate = { ...state };
      newstate.updated = true;
      const { linkId } = action.payload;
      const link = newstate.links[linkId];
      // Set the connected ports to hover
      if (link.to.nodeId && link.to.portId) {
        newstate.hovered = {};
      }
      return newstate;
    }
    case ON_LINK_CLICK: {
      const newstate = { ...state };
      const { linkId } = action.payload;

      if (
        newstate.selected.id !== linkId ||
        newstate.selected.type !== "link"
      ) {
        newstate.selected = {
          type: "link",
          id: linkId
        };
      }
      return newstate;
    }
    case ON_CANVAS_CLICK: {
      const newstate = { ...state };

      if (newstate.selected.id) {
        newstate.selected = {};
      }
      return newstate;
    }
    case ON_DELETE_KEY: {
      const newstate = { ...state };
      newstate.updated = true;
      if (newstate.selected.type === "node" && newstate.selected.id) {
        const node = newstate.nodes[newstate.selected.id];
        // Delete the connected links
        Object.keys(newstate.links).forEach(linkId => {
          const link = newstate.links[linkId];
          if (link.from.nodeId === node.id || link.to.nodeId === node.id) {
            delete newstate.links[link.id];
          }
        });
        // Delete the node
        delete newstate.nodes[newstate.selected.id];
      } else if (newstate.selected.type === "link" && newstate.selected.id) {
        delete newstate.links[newstate.selected.id];
      }
      if (newstate.selected) {
        newstate.selected = {};
      }
      return newstate;
    }
    case ON_NODE_CLICK: {
      const newstate = { ...state };
      const { nodeId } = action.payload;
      if (
        newstate.selected.id !== nodeId ||
        newstate.selected.type !== "node"
      ) {
        newstate.selected = {
          type: "node",
          id: nodeId
        };
      }
      return newstate;
    }
    case ON_NODE_SIZE_CHANGE: {
      const newstate = { ...state };
      newstate.updated = true;
      const { nodeId, size } = action.payload;
      newstate.nodes[nodeId] = {
        ...newstate.nodes[nodeId],
        size
      };
      return newstate;
    }
    case ON_PORT_POSITION_CHANGE: {
      const newstate = { ...state };
      newstate.updated = true;
      const { node: nodeToUpdate, port, el, nodesEl } = action.payload;
      if (nodeToUpdate.size) {
        // rotate the port's position based on the node's orientation prop (angle)
        const center = {
          x: nodeToUpdate.size.width / 2,
          y: nodeToUpdate.size.height / 2
        };
        const current = {
          x: el.offsetLeft + nodesEl.offsetLeft + el.offsetWidth / 2,
          y: el.offsetTop + nodesEl.offsetTop + el.offsetHeight / 2
        };
        const angle = nodeToUpdate.orientation || 0;
        const position = rotate(center, current, angle);

        const node = newstate.nodes[nodeToUpdate.id];
        node.ports[port.id].position = {
          x: position.x,
          y: position.y
        };

        newstate.nodes[nodeToUpdate.id] = { ...node };
      }

      return newstate;
    }
    case ON_CANVAS_DROP: {
      const newstate = { ...state };
      newstate.updated = true;
      const { config, data, position } = action.payload;
      const id = v4();
      newstate.nodes[id] = {
        id,
        position:
          config && config.snapToGrid
            ? {
                x: Math.round(position.x / 20) * 20,
                y: Math.round(position.y / 20) * 20
              }
            : position,
        orientation: data.orientation || 0,
        type: data.type,
        ports: data.ports,
        properties: data.properties
      };
      return newstate;
    }
    case ON_INPUT_CHANGE: {
      const newstate = { ...state };
      newstate.updated = true;
      const { nodeId, inputId, value } = action.payload;
      const inputs = newstate.nodes[nodeId].properties.inputs;
      Object.keys(inputs).forEach(key => {
        if (inputs[key].id === inputId) {
          inputs[key].value = value;
        }
      });
      return newstate;
    }
    case LOAD_JSON_CHART: {
      const {
        ...newstate
      } = require("../../views/FlowCharts/exampleCharts/threeIf.json");
      return newstate;
    }
    case SET_JSON_CHART: {
      console.log("loading state", action.payload);
      const newstate = action.payload;
      return newstate;
    }
    case DUMP_STATE: {
      console.log(JSON.stringify(state));
      return state;
    }
    case ADD_NEW_RECORD: {
      const newstate = { ...state };
      newstate.updated = true;
      newstate.records[action.payload.recordName] = action.payload;
      return newstate;
    }
    case SET_ENVVARS: {
      const newstate = { ...state };
      newstate.envvars = action.payload;
      return newstate;
    }
    case SET_METADATA: {
      const newstate = { ...state };
      newstate.metadata = action.payload;
      return newstate;
    }
    case DEL_ENVVAR: {
      const newstate = { ...state };
      newstate.updated = true;
      newstate.envvars.splice(newstate.envvars.indexOf(action.payload), 1);
      return newstate;
    }
    case ADD_ENVVAR: {
      const newstate = { ...state };
      newstate.updated = true;
      newstate.envvars.push(action.payload);
      return newstate;
    }
    case CHART_SAVE:
      return { ...state, updated: false };
    default: {
      return state;
    }
  }
};

const rotate = (center, current, angle) => {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const x =
    cos * (current.x - center.x) + sin * (current.y - center.y) + center.x;
  const y =
    cos * (current.y - center.y) - sin * (current.x - center.x) + center.y;
  return { x, y };
};

export default chart;
