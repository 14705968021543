const phoenixSocketReducer = (
  state = { joinedChannels: [], payload: {}, stats: {} },
  action
) => {
  switch (action.type) {
    case "PHOENIX_SOCKET_MESSAGE_FROM_CHANNEL":
      return {
        ...state,
        channel: action.channel,
        topic: action.messageType,
        payload: action.payload,
        stats: porcessPayload({ ...state.stats }, action.payload)
      };
    case "PHOENIX_SOCKET_JOINED_CHANNEL":
      return {
        ...state,
        joinedChannels: [...state.joinedChannels, action.channel],
        joinedChannel: action.channel
      };
    case "PHOENIX_SOCKET_PUSHED_MESSAGE":
      return {
        ...state,
        messageSent: true,
        messageSentTo: {
          channel: action.channel,
          topic: action.topic,
          message: action.message
        }
      };
    case "PHOENIX_SOCKET_PUSHED_MESSAGE_ERROR":
      return {
        ...state,
        messageSent: false,
        messageSentTo: {
          channel: action.channel,
          topic: action.topic,
          message: action.message,
          error: action.errror
        }
      };
    default:
      return state;
  }
};

const porcessPayload = (initStats, payload) => {
  const { stat_type } = payload;
  switch (stat_type) {
    case "vm_stats":
      return createVmStatsStructure(initStats, payload);
    default:
      return {};
  }
};

const createVmStatsStructure = (initStats, payload) => {
  const {
    label,
    values,
    value,
    type,
    stat_type,
    node,
    sub_stat_type
  } = payload;
  let nodeStats = initStats[node] || {};
  initStats[node] = nodeStats;
  let currentStatType = nodeStats[stat_type] || {};
  nodeStats[stat_type] = currentStatType;
  if (value) {
    currentStatType[sub_stat_type] = { value: value, type: type, label: label };
  } else {
    currentStatType[sub_stat_type] = {
      values: values,
      type: type,
      label: label
    };
  }
  return initStats;
};
export default phoenixSocketReducer;
